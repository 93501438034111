import { useEffect, useState } from "react";
import { fetchData } from "utils/functions/service";
import ScrollBar from "utils/scrollbar/ScrollBar";
import Cookies from "js-cookie";
import {
  UserStatusActif,
  UserStatusClose,
  UserStatusPause,
} from "utils/functions/enums";

function HomePage() {
  const [filter, setFilter] = useState<string>("agency");
  const [openSubStatuses, setOpenSubStatuses] = useState<string>("");
  const [openAgency, setOpenAgency] = useState<string>("");
  const roles = Cookies.get("roles");
  const [users, setUsers] = useState<
    Array<{
      id: number;
      name: string;
      status: string;
      closeStatus: string;
      actifStatus: string;
      pauseStatus: string;
      date_start: string;
      date_end: string;
      agency: { name: string };
      employee: { name: string; surname: string };
    }>
  >([]);

  useEffect(() => {
    async function fetch() {
      setUsers(await fetchData(`/users`, roles));
    }
    fetch();
  }, []);

  interface AgencyResult {
    count: number;
    Actif: Record<string, any>;
    "Suspendu.e": Record<string, any>;
    "Clôturé.e": Record<string, any>;
  }

  const initializeAgencyResult = (): AgencyResult => {
    let result: AgencyResult = {
      count: 0,
      Actif: { count: 0 },
      "Suspendu.e": { count: 0 },
      "Clôturé.e": { count: 0 },
    };

    Object.keys(UserStatusActif).forEach((key) => {
      result["Actif"][UserStatusActif[key]] = { count: 0 };
    });

    Object.keys(UserStatusPause).forEach((key) => {
      result["Suspendu.e"][UserStatusPause[key]] = { count: 0 };
    });

    Object.keys(UserStatusClose).forEach((key) => {
      result["Clôturé.e"][UserStatusClose[key]] = { count: 0 };
    });
    return result;
  };

  const getNumberOfCandidatesForAllAgencies = (
    users: any[]
  ): Record<string, AgencyResult> => {
    let result: Record<string, AgencyResult> = {};
    users.forEach((user) => {
      const agencyName = user.agency.name;

      // Initialiser l'objet pour l'agence si elle n'existe pas
      if (!result[agencyName]) {
        result[agencyName] = initializeAgencyResult();
      }
      result[agencyName].count++;

      let status = user.status;
      let subStatus =
        status === "Actif"
          ? user.actifStatus
          : status === "Suspendu.e"
          ? user.pauseStatus
          : user.closeStatus;

      subStatus = subStatus === "" ? "Non renseignée" : subStatus;

      result[agencyName][status].count++;
      result[agencyName][status][subStatus].count++;

      const employeeName =
        user.employee === undefined
          ? "Non renseigné"
          : user.employee.name + " " + user.employee.surname;

      // Incrémente le compteur pour l'employé sous le sous-statut
      if (!result[agencyName][status][subStatus][employeeName]) {
        result[agencyName][status][subStatus][employeeName] = 1;
      } else {
        result[agencyName][status][subStatus][employeeName]++;
      }
    });

    return result;
  };

  function getEmployeesForAgency(agencyName, data) {
    const employees = new Set();

    if (data[agencyName]) {
      const statuses = ["Actif", "Clôturé.e", "Suspendu.e"]; // Les statuts à considérer

      statuses.forEach((status) => {
        const statusData = data[agencyName][status];

        Object.keys(statusData).forEach((subStatus) => {
          // Récupère les employés pour chaque sous-statut, sauf le compteur 'count'
          if (subStatus !== "count" && statusData[subStatus]) {
            Object.keys(statusData[subStatus]).forEach((employee) => {
              // Vérifie si l'employé existe déjà dans le Set avant de l'ajouter
              if (employee !== "count") {
                employees.add(employee);
              }
            });
          }
        });
      });
    }

    return Array.from(employees); // Convertit le Set en tableau pour obtenir la liste des employés uniques
  }

  interface StatusData {
    count: number;
    [key: string]: any;
  }

  const cell = {
    width: "10vw",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    border: "0.1vw solid black",
    alignItems: "center",
  };

  const actif = {
    backgroundColor: "#F79646",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const subActif = {
    backgroundColor: "#E26B0A",
    color: "white",
    fontSize: "12px",
    display: "flex",
    minHeight: "5vh",
    justifyContent: "center",
    alignItems: "center",
  };

  const close = {
    backgroundColor: "#C0504D",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const subClose = {
    backgroundColor: "#F2DCDB",
    color: "black",
    display: "flex",
    fontSize: "12px",
    justifyContent: "center",
    alignItems: "center",
  };

  const pause = {
    backgroundColor: "#1F497D",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const subPause = {
    backgroundColor: "#C5D9F1",
    color: "black",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const other = {
    backgroundColor: "#9BBB59",
    color: "black",
    fontWeight: "bolder",
  };
  const subOther = {
    backgroundColor: "#76933C",
    color: "black",
    fontWeight: "bolder",
  };
  console.log(getNumberOfCandidatesForAllAgencies(users));
  return (
    <div
      style={{
        width: "99vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2
        style={{
          marginBottom: "2vh",
        }}
      >
        Page d'accueil
      </h2>
      <div
        style={{
          marginBottom: "2vh",
        }}
        id="filtre"
      ></div>
      <ScrollBar>
        <div
          id="table"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div id="head">
            <div
              id="status"
              style={{
                display: "flex",
              }}
            >
              <span
                style={{
                  ...cell,
                  ...other,
                  fontWeight: "bolder",
                }}
              >
                Agence
              </span>
              <span
                style={{
                  ...cell,
                  ...actif,
                  textAlign: "center",
                }}
                onClick={() =>
                  setOpenSubStatuses(openSubStatuses !== "Actif" ? "Actif" : "")
                }
              >
                {openSubStatuses === "Actif" ? "-" : "+"} Actif
              </span>

              {openSubStatuses === "Actif" &&
                Object.keys(UserStatusActif).map((key, index) => {
                  if (index !== Object.keys(UserStatusActif).length - 1) {
                    return <span style={{ ...cell, ...actif }}></span>;
                  }
                })}
              {openSubStatuses === "Actif" && (
                <span
                  style={{
                    ...cell,
                    ...actif,
                    fontWeight: "bolder",
                  }}
                >
                  Total Actif
                </span>
              )}
              <span
                style={{
                  ...cell,
                  ...pause,
                  textAlign: "center",
                }}
                onClick={() =>
                  setOpenSubStatuses(
                    openSubStatuses !== "Suspendu.e" ? "Suspendu.e" : ""
                  )
                }
              >
                {openSubStatuses === "Suspendu.e" ? "-" : "+"} Suspendu.e
              </span>
              {openSubStatuses === "Suspendu.e" &&
                Object.keys(UserStatusPause).map((key, index) => {
                  if (index !== Object.keys(UserStatusPause).length - 1) {
                    return <span style={{ ...cell, ...pause }}></span>;
                  }
                })}
              {openSubStatuses === "Suspendu.e" && (
                <span
                  style={{
                    ...cell,
                    ...pause,
                    fontWeight: "bolder",
                  }}
                >
                  Total Suspendu.e
                </span>
              )}
              <span
                style={{
                  ...cell,
                  ...close,
                  textAlign: "center",
                }}
                onClick={() =>
                  setOpenSubStatuses(
                    openSubStatuses !== "Clôturé.e" ? "Clôturé.e" : ""
                  )
                }
              >
                {openSubStatuses === "Clôturé.e" ? "-" : "+"} Clôturé.e
              </span>
              {openSubStatuses === "Clôturé.e" &&
                Object.keys(UserStatusClose).map((key, index) => {
                  if (index !== Object.keys(UserStatusClose).length - 1) {
                    return <span style={{ ...cell, ...close }}></span>;
                  }
                })}
              {openSubStatuses === "Clôturé.e" && (
                <span
                  style={{
                    ...cell,
                    ...close,
                    fontWeight: "bolder",
                  }}
                >
                  Total Clôturé.e
                </span>
              )}
              <span
                style={{
                  ...cell,
                  ...other,
                  textAlign: "center",
                }}
              >
                Total général
              </span>
            </div>
            <div
              id="subStatus"
              style={{
                display: "flex",
              }}
            >
              <span
                style={{
                  ...cell,
                  ...subOther,
                  fontWeight: "bolder",
                }}
              ></span>
              {openSubStatuses === "Actif" &&
                Object.keys(UserStatusActif).map((key) => {
                  return (
                    <span style={{ ...cell, ...subActif, textAlign: "center" }}>
                      {UserStatusActif[key]}
                    </span>
                  );
                })}
              <span
                style={{
                  ...cell,
                  ...subActif,
                }}
              ></span>
              {openSubStatuses === "Suspendu.e" &&
                Object.keys(UserStatusPause).map((key) => {
                  return (
                    <span style={{ ...cell, ...subPause, textAlign: "center" }}>
                      {UserStatusPause[key]}
                    </span>
                  );
                })}
              <span
                style={{
                  ...cell,
                  ...subPause,
                }}
              ></span>
              {openSubStatuses === "Clôturé.e" &&
                Object.keys(UserStatusClose).map((key) => {
                  return (
                    <span style={{ ...cell, ...subClose, textAlign: "center" }}>
                      {UserStatusClose[key]}
                    </span>
                  );
                })}
              <span
                style={{
                  ...cell,
                  ...subClose,
                }}
              ></span>
              <span
                style={{
                  ...cell,
                  ...subOther,
                }}
              ></span>
            </div>
          </div>
          {Object.entries(getNumberOfCandidatesForAllAgencies(users)).map(
            ([agencyName, agencyData]) => {
              // Récupère tous les employés pour l'agence
              const employees = getEmployeesForAgency(
                agencyName,
                getNumberOfCandidatesForAllAgencies(users)
              );
              console.log(employees);

              // Créer un tableau pour stocker les éléments du total de l'employé
              const totalEmployeeStats: JSX.Element[] = [];

              employees.forEach((employee) => {
                let totalEmployeeCount = 0; // Total des compteurs pour l'employé

                // Créer les cellules pour chaque statut et sous-statut
                const employeeStats = Object.keys(agencyData).map((status) => {
                  if (status !== "count") {
                    let totalCountForStatus = 0; // Total des compteurs pour ce statut

                    // Itérer à travers les sous-statuts
                    const subStatuses = Object.keys(agencyData[status]).map(
                      (subStatus) => {
                        if (subStatus !== "count") {
                          const count =
                            agencyData[status][subStatus][employee] || 0; // Obtenir le compteur pour l'employé

                          totalCountForStatus += count; // Calculer le total pour ce statut
                          totalEmployeeCount += count; // Calculer le total pour l'employé

                          return (
                            openSubStatuses === status && (
                              <span style={cell} key={`E_${subStatus}`}>
                                {count}
                              </span>
                            )
                          );
                        }
                        return null;
                      }
                    );

                    // Ajouter le total pour ce statut à la fin des sous-statuts
                    subStatuses.push(
                      <span style={cell} key={`E_${status}-total`}>
                        {totalCountForStatus}
                      </span>
                    );

                    return subStatuses;
                  }
                  return null;
                });

                // Ajouter le total pour l'employé à la fin des stats pour cet employé
                totalEmployeeStats.push(
                  <div className="bodyEmployee" key={`employee-${employee}`}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span style={cell}>{String(employee)}</span>
                      {employeeStats}
                      <span style={cell}>{totalEmployeeCount}</span>
                    </div>
                  </div>
                );
              });

              return (
                <>
                  {/* Body for agency */}
                  <div className="bodyAgency">
                    <div
                      key={agencyName}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div
                        style={{
                          ...cell,
                          ...other,
                          color: "white",
                          cursor: "pointer",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "flex-start",
                        }}
                        onClick={() =>
                          setOpenAgency(
                            openAgency === agencyName ? "" : agencyName
                          )
                        }
                      >
                        <span
                          style={{
                            margin: "0 0.3vw",
                          }}
                        >
                          {openAgency === agencyName ? "-" : "+"}
                        </span>
                        <span>{agencyName}</span>
                      </div>
                      {/* Iterate through Actif, Clôturé.e, Suspendu.e */}
                      {Object.keys(agencyData).map((status) => {
                        if (status !== "count") {
                          let totalCount = 0; // Variable temporaire pour stocker le total
                          // Iterate through sub-statuses
                          const subStatuses = Object.keys(
                            agencyData[status]
                          ).map((subStatus) => {
                            if (subStatus !== "count") {
                              totalCount += agencyData[status][subStatus].count; // Calcul du total
                              return (
                                openSubStatuses === status && (
                                  <span style={cell} key={`A_${subStatus}`}>
                                    {agencyData[status][subStatus].count}
                                  </span>
                                )
                              );
                            }
                            return null;
                          });

                          // Ajout du total à la fin de l'itération des sous-statuts
                          subStatuses.push(
                            <span style={cell} key={`${status}-total`}>
                              {totalCount}
                            </span>
                          );

                          return subStatuses;
                        }
                        return null;
                      })}

                      <span
                        style={{
                          ...cell,
                          ...subOther,
                          fontWeight: "bolder",
                        }}
                      >
                        {agencyData.count}
                      </span>
                    </div>
                  </div>

                  {/* Body for employees */}
                  {agencyName === openAgency && (
                    <div className="bodyEmployee">{totalEmployeeStats}</div>
                  )}
                </>
              );
            }
          )}
        </div>
      </ScrollBar>
    </div>
  );
}

export default HomePage;
