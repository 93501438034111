import { ReactComponent as SpinnerSvg } from "images/spinner.svg";
import { useState, useEffect } from "react";
import "./spinner.css";

const Spinner = ({
  showFirstText = true,
  forButton = false,
  putAbsolute = true,
}) => {
  const [timeIsOut, setTimeIsOut] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimeIsOut(true);
    }, 5000);
  }, []);

  return (
    <div
      className={`main ${forButton ? "forButton" : ""} ${
        putAbsolute ? "putAbsolute" : ""
      }`}
    >
      {!forButton && (
        <span className="text">
          {showFirstText ? (
            <span>Connexion en cours</span>
          ) : (
            <span>Chargement en cours</span>
          )}
        </span>
      )}
      <SpinnerSvg />
      {timeIsOut && (
        <span className="text">
          Si le chargement persiste, veuillez rafraîchir la page
        </span>
      )}
    </div>
  );
};

export default Spinner;
