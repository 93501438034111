import jwt_decode from "jwt-decode";

export function getExpirationCookies(token) {
  if (token) {
    try {
      const decodedToken = jwt_decode(token) as { exp: number };
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = new Date().getTime();
      const timeRemaining = expirationTime - currentTime;
      const secondesRemaining = Math.floor(timeRemaining / 1000);
      return secondesRemaining;
    } catch (error) {}
  }
  return 0;
}
