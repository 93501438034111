import { useEffect, useState, useCallback } from "react";
import useDocumentTitle, { fetchData } from "utils/functions/service";
import Cookies from "js-cookie";
import { buttonNew, focusStyle, itemList, selectedItem } from "utils/functions/themes";
import Action from "./Action";
import { useLocation } from "react-router-dom";
import Clouds from "utils/clouds/Clouds";
import ScrollBar from "utils/scrollbar/ScrollBar";
import Spinner from "utils/spinner/Spinner";

function ActionsList() {
  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState<
    Array<{ id: string; name: string; category: string }>
  >([]);
  const [sortedActions, setSortedActions] = useState<
    Record<string, Array<{ id: string; name: string }>>
  >({});

  const [numAction, setNumAction] = useState(null);
  const [focus, setFocus] = useState(null);
  const roles = Cookies.get("roles");
  const location = useLocation();

  useDocumentTitle(`Liste des ateliers`);

  const updateActions = async () => {
    setActions(await fetchData(`/actions`, roles));
  };

  const deleteActions = async (id: number) => {
    setNumAction(null);
    let newActions = await fetchData(`/actions`, roles);
    setActions(newActions.filter((a) => a.id !== id));
  };

  useEffect(() => {
    async function fetch() {
      setActions(await fetchData(`/actions`, roles));
    }
    fetch();
  }, [location, roles]);

  useEffect(() => {
    if (actions.length > 0) setIsLoading(false);
    const sortedCategories: Record<
      string,
      Array<{ id: string; name: string }>
    > = {};

    actions.forEach((action) => {
      if (!sortedCategories[action.category]) {
        sortedCategories[action.category] = [];
      }
      sortedCategories[action.category].push({
        id: action.id,
        name: action.name,
      });
    });

    // Tri des actions dans chaque catégorie par nom
    for (const category in sortedCategories) {
      sortedCategories[category].sort((a, b) => a.name.localeCompare(b.name));
    }

    setSortedActions(sortedCategories);
  }, [actions]);

  const handleKeyDown = useCallback((event, actionId, fcnt) => {
    if (event.which === 13 || event.which === 32) {
      fcnt(actionId);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "80vh",
        overflow: "hidden",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "48vw" }}>
        <ScrollBar>
          <div
            style={{
              rowGap: "3vh",
              paddingTop: "4vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              tabIndex={0}
              style={buttonNew}
              onClick={() => setNumAction(0)}
              onKeyDown={(event) => handleKeyDown(event, 0, setNumAction)}
            >
              Nouvel atelier
            </div>
            {isLoading ? (
              <Spinner showFirstText={false} />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  rowGap: "2vh",
                }}
              >
                {Object.entries(sortedActions).map(
                  ([category, categoryActions]) => (
                    <div
                      key={category}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "1vh",
                      }}
                    >
                      <h2>{category}</h2>
                      {categoryActions.map((action) => (
                        <div
                          tabIndex={0}
                          onFocus={() => setFocus(action.id)}
                          onBlur={() => setFocus(null)}
                          key={action.id}
                          onClick={() => setNumAction(action.id)}
                          onKeyDown={(event) =>
                            handleKeyDown(event, action.id, setNumAction)
                          }
                          style={
                            numAction && numAction === action.id
                              ? Object.assign({}, itemList, selectedItem)
                              : focus === action.id
                              ? Object.assign(
                                  {},
                                  itemList,
                                  selectedItem,
                                  focusStyle
                                )
                              : itemList
                          }
                        >
                          <span
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {action.id == "50" ? <>&#128128;</> : action.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </ScrollBar>
      </div>
      <div
        style={{
          borderLeft: "0.2vw solid #203864",
          height: "80%",
          marginTop: "4vh",
        }}
      ></div>
      <div
        style={{
          width: "48vw",
          overflowY: "auto",
          minHeight: "40vh",
        }}
      >
        {numAction !== null ? (
          <ScrollBar direction="left">
            <Action
              id={numAction}
              updateActions={updateActions}
              deleteActions={deleteActions}
              mode={numAction !== 0 ? "update" : "create"}
            />
          </ScrollBar>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1vw",
            }}
          >
            Veuillez sélectionner un atelier 😁
          </div>
        )}
      </div>
      <Clouds />
    </div>
  );
}

export default ActionsList;
