import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import Cookies from "js-cookie";
import { deleteData, fetchData, postData } from "utils/functions/service";
import { ReactComponent as FileIcon } from "images/file.svg";
import Spinner from "utils/spinner/Spinner";

export const ShowFile = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const roles = Cookies.get("roles");

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [files, setFiles] = useState<Array<{ fileName: string }>>([]);

  const openDeleteDialog = async () => {
    setDeleteDialogOpen(true);
    setFiles(await fetchData(`/users/monitorings/${id}/files`, "admin"));
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleUploadFile = async (e) => {
    const files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`files`, files[i]);
    }
    await postData(`/users/monitoring/${id}/files`, formData, roles);
  };

  const handleDeleteFile = async (fileName) => {
    setIsLoading(true);
    try {
      await deleteData(`/users/monitorings/file/${fileName}`, "admin");
      setFiles(await fetchData(`/users/monitorings/${id}/files`, "admin"));
    } catch (error) {
      console.error("Erreur lors de la suppression du fichier");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadFile = async (fileName) => {
    try {
      const fileUrl = `${process.env.REACT_APP_BASE_URL}/users/monitorings/files/${fileName}`;

      fetch(fileUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erreur lors du téléchargement du fichier");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
        });
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier :", error);
    }
  };

  return (
    <div>
      <Button
        style={{
          color: "#024873",
          fontWeight: "900",
        }}
        onClick={openDeleteDialog}
      >
        Voir les fichiers
      </Button>
      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "auto",
              maxWidth: "100vw",
              alignItems: "center",
            },
          },
        }}
      >
        <DialogTitle>Suivi numéro {id}</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Spinner forButton={true} putAbsolute={false} />
          ) : (
            <DialogContentText>
              {files.map((file, index) => (
                <span
                  key={index}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    title="Télécharger le fichier"
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      width: "10vw",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    onClick={handleDownloadFile.bind(this, file.fileName)}
                  >
                    <FileIcon />
                    <span
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      {file.fileName}
                    </span>
                    <span
                      title="Supprimer le fichier"
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        cursor: "pointer",
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "2rem",
                      }}
                      onClick={handleDeleteFile.bind(this, file.fileName)}
                    >
                      &times;
                    </span>
                  </span>
                </span>
              ))}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <input type="file" multiple onChange={handleUploadFile} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
