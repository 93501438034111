import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDocumentTitle, {
  deleteData,
  fetchData,
  putData,
  postData,
} from "utils/functions/service";
import Cookies from "js-cookie";
import { buttonUpdate, inputField } from "utils/functions/themes";
import Spinner from "utils/spinner/Spinner";
import isAdmin from "utils/functions/isAdmin";
import DeleteButton from "utils/DeleteButton";
import { Category } from "utils/functions/enums";

function Action({ id, updateActions, deleteActions, mode }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [action, setAction] = useState({ name: "", time: 0, category: "" });
  const categoryOptions = Object.values(Category);
  const [update, setUpdate] = useState("");
  const [error, setError] = useState({ name: "", time: "" });
  const roles = Cookies.get("roles");

  useDocumentTitle(
    `${mode === "update" ? action.name + " - Modification" : "Nouvel atelier"}`
  );

  useEffect(() => {
    async function fetch() {
      setAction(await fetchData(`/actions/${id}`, roles));
    }
    if (mode === "update") {
      fetch();
    }
  }, [mode, id, roles]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    action.category = action.category ? action.category : categoryOptions[0];
    if (action.name && action.time) {
      setIsLoading(true);
      try {
        if (mode === "create") {
          await postData(`/actions`, action, roles);
          navigate("/actions");
        } else {
          await putData(`/actions/${id}`, action, roles);
          setUpdate("Mise à jour effectuée");
          updateActions();
        }
      } catch (error) {
        setUpdate(
          "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
        );
      }
      setIsLoading(false);
    } else setUpdate("");
    if (!action.name) {
      setError((prevError) => ({
        ...prevError,
        name: "Veuillez saisir un nom s'il vous plait",
      }));
    }
    if (!action.time) {
      setError((prevError) => ({
        ...prevError,
        time: "Veuillez saisir une durée s'il vous plait",
      }));
    }
  };

  const handleChange = (event) => {
    setAction({ ...action, [event.target.name]: event.target.value });
    if (event.target.name === "name") {
      setError((prevError) => ({
        ...prevError,
        name:
          event.target.value.trim() !== ""
            ? ""
            : "Veuillez saisir un nom s'il vous plait",
      }));
    } else if (event.target.name === "time") {
      setError((prevError) => ({
        ...prevError,
        time:
          event.target.value.trim() !== ""
            ? ""
            : "Veuillez saisir une durée s'il vous plait",
      }));
    }
  };

  const handleDelete = async (closeModal: () => void) => {
    setIsLoading(true);
    try {
      await deleteData(`/actions/delete/${id}`, roles);
      deleteActions(id);
      closeModal();
    } catch (error) {
      setUpdate(
        "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const defaultCategory = categoryOptions.length > 0 ? categoryOptions[0] : "";
  const category = categoryOptions.find((a) => a === action.category)
    ? action.category
    : defaultCategory;

  return (
    <div style={{ paddingTop: "2vh" }}>
      {update}
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "2vh",
          alignItems: "center",
        }}
      >
        <TextField
          label="Nom de l'atelier"
          name="name"
          value={action.name}
          onChange={handleChange}
          error={Boolean(error.name)}
          helperText={error.name}
          sx={inputField}
        />
        <TextField
          label="Durée de l'atelier"
          name="time"
          type="number"
          value={action.time}
          onChange={handleChange}
          error={Boolean(error.time)}
          helperText={error.time}
          sx={inputField}
        />
        <Select
          sx={inputField}
          label="Mobilité"
          name="category"
          value={category}
          onChange={handleChange}
        >
          {categoryOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "5vw",
          }}
        >
          {isLoading ? (
            <Spinner forButton={true} putAbsolute={false} />
          ) : (
            <>
              <Button style={buttonUpdate} type="submit">
                {mode === "create"
                  ? "Créer un atelier"
                  : "Mettre à jour l'atelier"}
              </Button>
              {mode === "update" && isAdmin() && (
                <DeleteButton handleDelete={handleDelete} />
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
}

export default Action;
