import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDocumentTitle, {
  deleteData,
  fetchData,
  putData,
  postData,
} from "utils/functions/service";
import Cookies from "js-cookie";
import { buttonDelete, buttonUpdate, inputField } from "utils/functions/themes";
import * as bcrypt from "bcryptjs";
import DeleteButton from "utils/DeleteButton";
import isAdmin from "utils/functions/isAdmin";
import Spinner from "utils/spinner/Spinner";

function Funder({ id, updateFunders, deleteFunders, mode, listFunders }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [funder, setFunder] = useState({
    name: "",
    people_name: "",
    people_surname: "",
    job: "",
    email: "",
    phone: "",
  });
  const [update, setUpdate] = useState("");
  const [error, setError] = useState({
    name: { text: "un nom", value: "" },
  });
  const roles = Cookies.get("roles");
  useDocumentTitle(
    `${
      mode === "update"
        ? "Financeur - " + funder.name + " - Modification"
        : "Nouveau financeur"
    }`
  );

  useEffect(() => {
    async function fetch() {
      setFunder(await fetchData(`/funders/${id}`, roles));
    }
    if (mode === "update") {
      fetch();
    } else {
      setFunder({
        name: "",
        people_name: "",
        people_surname: "",
        job: "",
        email: "",
        phone: "",
      });
    }
  }, [mode, id, roles]);

  const requiredFields = {
    name: "un nom",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let formIsValid = true;
    let newErrors = {
      name: { text: "un nom", value: "" },
    };
    Object.entries(requiredFields).forEach(([fieldName, fieldLabel]) => {
      if (!funder[fieldName]) {
        formIsValid = false;
        newErrors[fieldName] = {
          value: `Veuillez saisir ${fieldLabel} s'il vous plaît`,
          text: requiredFields[fieldName],
        };
      }
    });
    setError(newErrors);

    if (formIsValid) {
      setIsLoading(true);
      try {
        if (mode === "create") {
          await postData(`/funders`, funder, roles);
          navigate("/funders");
        } else {
          await putData(`/funders/${id}`, funder, roles);
          setUpdate("Mise à jour effectuée");
          updateFunders();
        }
      } catch (error) {
        setUpdate(
          "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
        );
      }
    } else setUpdate("");
    setIsLoading(false);
  };

  const handleChange = (event) => {
    setFunder({ ...funder, [event.target.name]: event.target.value });
    const { name, value } = event.target;

    if (["name"].includes(name)) {
      setError((prevError) => ({
        ...prevError,
        [name]: {
          text: prevError[name].text,
          value:
            value.trim() !== ""
              ? ""
              : `Veuillez saisir ${prevError[name].text} s'il vous plaît`,
          double: false,
        },
      }));
    }

    if (name === "phone") {
      const formattedPhone = value.trim().replace(/[^0-9]/g, ""); // Supprimer tous les caractères non numériques
      const formattedValue = [];

      for (let i = 0; i < formattedPhone.length; i++) {
        formattedValue.push(formattedPhone[i]);

        if ((i + 1) % 2 === 0 && i !== formattedPhone.length - 1) {
          formattedValue.push(" ");
        }
      }

      setFunder((prevFunder) => ({
        ...prevFunder,
        phone: formattedValue.join(""),
      }));
    }
  };

  const handleDelete = async (closeModal: () => void) => {
    setIsLoading(true);
    try {
      await deleteData(`/funders/delete/${id}`, roles);
      deleteFunders(id);
      closeModal();
    } catch (error) {
      setUpdate(
        "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ paddingTop: "2vh" }}>
      {update}
      {isLoading ? (
        <Spinner forButton={true} putAbsolute={false} />
      ) : (
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "2vh",
            alignItems: "center",
          }}
        >
          <TextField
            label="Nom du financeur"
            name="name"
            value={funder.name}
            onChange={handleChange}
            error={Boolean(error.name.value)}
            helperText={error.name.value}
            sx={inputField}
          />
          <TextField
            label="Nom du responsable"
            name="people_name"
            value={funder.people_name}
            onChange={handleChange}
            sx={inputField}
          />
          <TextField
            label="Prenom du responsable"
            name="people_surname"
            value={funder.people_surname}
            onChange={handleChange}
            sx={inputField}
          />
          <TextField
            label="Fonction du responsable"
            name="job"
            value={funder.job}
            onChange={handleChange}
            sx={inputField}
          />
          <TextField
            label="Email du responsable"
            name="email"
            value={funder.email}
            onChange={handleChange}
            sx={inputField}
          />
          <TextField
            label="Téléphone du responsable"
            name="phone"
            value={funder.phone}
            onChange={handleChange}
            sx={inputField}
            inputProps={{
              pattern: "[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}", // Utiliser un pattern pour forcer le format XX-XX-XX-XX-XX
              maxLength: 14, // Limiter le nombre de caractères à 14 (pour prendre en compte les tirets)
            }}
          />
          <span>Nombre total de bénéficiaires rattachés : X</span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "5vw",
            }}
          >
            <Button style={buttonUpdate} type="submit">
              {mode === "create"
                ? "Créer le financeur"
                : "Mettre à jour le financeur"}
            </Button>
            {mode === "update" && isAdmin() && (
              <DeleteButton handleDelete={handleDelete} />
            )}
          </div>
        </form>
      )}
    </div>
  );
}

export default Funder;
