import { useEffect, useState } from "react";
import { fetchData } from "utils/functions/service";
import Cookies from "js-cookie";
import ScrollBar from "utils/scrollbar/ScrollBar";
import { UserStatus, UserStatusClose } from "utils/functions/enums";

function Stats() {
  const roles = Cookies.get("roles");
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState("");
  const [stats, setStats] = useState<
    Array<{
      status: string;
      poec: boolean;
      closeStatus: string;
      actifStatus: string;
      pauseStatus: string;
      createdAt: string;
      agency: { name: string };
      date_start: string;
      date_end: string;
      totalMonitoring: number;
      totalMonitoringTime: number;
    }>
  >([]);
  const [filteredStats, setFilteredStats] = useState<
    Array<{
      status: string;
      poec: boolean;
      closeStatus: string;
      actifStatus: string;
      pauseStatus: string;
      createdAt: string;
      agency: { name: string };
      totalMonitoring: number;
      totalMonitoringTime: number;
    }>
  >([]);

  const [totalUser, setTotalUser] = useState(0);
  const [totalPOEC, setTotalPOEC] = useState(0);
  const [totalCloture, setTotalCloture] = useState(0);
  const [monitoringsAverage, setMonitoringsAverage] = useState(0);
  const [monitoringsAverageTime, setMonitoringsAverageTime] = useState("");
  const [totalCPRO, setTotalCPRO] = useState(0);
  const [totalCDD, setTotalCDD] = useState(0);
  const [totalOtherFormation, setTotalOtherFormation] = useState(0);
  const [totalBackInstitute, setTotalBackInstitute] = useState(0);

  useEffect(() => {
    const getStat = async () => {
      try {
        setStats(await fetchData(`/users`, roles));
        setAgencies(await fetchData("/agencies", roles));
      } catch (error) {
        throw new Error(error);
      }
    };
    getStat();
  }, []);
  useEffect(() => {
    setFilteredStats(stats);
  }, [stats]);

  useEffect(() => {
    setTotalUser(filteredStats.length);
  }, [filteredStats]);

  useEffect(() => {
    let totalMonitoringTimeSum = 0;
    let totalMonitoringSum = 0;
    for (const candidat of filteredStats) {
      totalMonitoringTimeSum += candidat.totalMonitoringTime;
      totalMonitoringSum += candidat.totalMonitoring;
    }
    setTotalPOEC(filteredStats.filter((user) => user.poec === true).length);
    setTotalCloture(
      filteredStats.filter((user) => user.status === UserStatus.KOOSHII_END)
        .length
    );
    setTotalCPRO(
      filteredStats.filter(
        (user) => user.closeStatus === UserStatusClose.KOOSHII_CPRO
      ).length
    );
    setTotalCDD(
      filteredStats.filter(
        (user) => user.closeStatus === UserStatusClose.KOOSHII_CDDCDI
      ).length
    );
    setTotalOtherFormation(
      filteredStats.filter(
        (user) => user.closeStatus === UserStatusClose.KOOSHII_REO_NEWFORMATION
      ).length
    );
    setTotalBackInstitute(
      filteredStats.filter(
        (user) => user.closeStatus === UserStatusClose.KOOSHII_REO_PRESCRIPTEUR
      ).length
    );
    setMonitoringsAverageTime(
      totalUser > 0
        ? formatDuration(totalMonitoringTimeSum / totalUser)
        : "0 min"
    );

    // Mettre à jour monitoringsAverage à un chiffre après la virgule
    setMonitoringsAverage(
      totalUser > 0 ? roundToDecimal(totalMonitoringSum / totalUser, 1) : 0
    );
  }, [totalUser]);

  function formatDuration(minutes) {
    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes % 1440) / 60);
    const remainingMinutes = Math.floor(minutes % 60);
    let formattedDuration = "";

    if (days > 0) {
      formattedDuration += `${days} jour${days > 1 ? "s" : ""} `;
    }

    if (hours > 0) {
      formattedDuration += `${hours} h `;
    }

    if (remainingMinutes > 0) {
      formattedDuration += `${remainingMinutes} min`;
    }

    return formattedDuration.trim();
  }

  function roundToDecimal(value, decimalPlaces) {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(value * multiplier) / multiplier;
  }

  function filterStats() {
    let agencyInput = document.getElementById(
      "agency"
    ) as HTMLInputElement | null;

    let filteredStats = [...stats];

    if (agencyInput && agencyInput.value !== "") {
      filteredStats = filteredStats.filter(
        (user) => user.agency["id"] === parseInt(agencyInput.value)
      );
    }

    setFilteredStats(filteredStats);
  }

  return (
    <>
      <div
        style={{
          marginBottom: "2vh",
        }}
        id="filtre"
      >
        <select
          name="agency"
          id="agency"
          onChange={(event) => {
            filterStats();
            setSelectedAgency(event.target.value);
          }}
        >
          <option value="">Toutes agences</option>
          {agencies.map((agency) => (
            <option key={agency.id} value={agency.id}>
              {agency.name}
            </option>
          ))}
        </select>
      </div>
      <h1>Stats</h1>
      <div
        style={{
          marginTop: "3rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ScrollBar>
          <span className="stat-agency">
            {selectedAgency
              ? agencies.find((agency) => agency.id === parseInt(selectedAgency))?.name.toUpperCase()
              : "TOUTES AGENCES"}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "3rem",
              marginTop: "0.3rem",
            }}
          >
            <div className="blockStat">
              <span className="stat-title">TYPES DE PUBLIC ACCOMPAGNES</span>
              <span>
                <span className="stat-number">{totalUser}</span> personnes
                accompagnées :
              </span>
              <li>
                <span>
                  <span className="stat-number">{totalPOEC}</span> en POEC
                </span>
              </li>
              <li>
                <span>
                  <span className="stat-number">{totalCloture}</span> en
                  individuel
                </span>
              </li>
            </div>

            <div className="blockStat">
              <span className="stat-title">ACCOMPAGNEMENT</span>
              <span>Pour les parcours individuel :</span>
              <li>
                <span>
                  Nb de RDV moyen :{" "}
                  <span className="stat-number">{monitoringsAverage}</span> RDV
                </span>
              </li>
              <li>
                <span>
                  Durée moyenne des parcours :{" "}
                  <span className="stat-number">{monitoringsAverageTime}</span>
                </span>
              </li>
              <span>Pour les POEC, un parcours spécifique est défini</span>
            </div>

            <div className="blockStat">
              <span className="stat-title">SORTIES</span>
              <span>
                Sur les <span className="stat-number">{totalUser}</span>,{" "}
                <span className="stat-number">{totalCloture}</span> ont une
                sortie positive
              </span>
              <li>
                <span>
                  <span className="stat-number">{totalCPRO}</span> démarrages
                  CPRO
                </span>
              </li>
              <li>
                <span>
                  <span className="stat-number">{totalCDD}</span> CDD Hors
                  secteur (Manutention pour les JO)
                </span>
              </li>
              <li>
                <span>
                  <span className="stat-number">{totalOtherFormation}</span>{" "}
                  réorientation vers une formation
                </span>
              </li>
              <li>
                <span>
                  <span className="stat-number">{totalBackInstitute}</span>{" "}
                  réorientation vers leur structure
                </span>
              </li>
            </div>
          </div>
        </ScrollBar>
      </div>
    </>
  );
}

export default Stats;
