import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import moment from "moment";
import "moment/locale/fr";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDocumentTitle, {
  deleteData,
  fetchData,
  putData,
} from "utils/functions/service";
import Cookies from "js-cookie";
import Spinner from "utils/spinner/Spinner";
import { SearchOptions } from "entity/user/monitoring/SearchOptions";
import { MonitoringStatus } from "utils/functions/enums";
import ScrollBar from "utils/scrollbar/ScrollBar";

function EditMonitoring() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [actions, setActions] = useState<
    Array<{ id: string; name: string; category: string; time: number }>
  >([]);
  const [sortedActions, setSortedActions] = useState<
    Record<string, Array<{ id: string; name: string; time: number }>>
  >({});
  const [monitoring, setMonitoring] = useState({
    date: new Date(),
    remark: "",
    time: 0,
    status: "",
    action: "",
    user: "",
  });
  const [update, setUpdate] = useState("");
  const [error, setError] = useState({
    date: "",
    time: "",
  });
  const roles = Cookies.get("roles");
  const [user, setUser] = useState({ id: 0, name: "", surname: "" });

  const statusOption = Object.values(MonitoringStatus);

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    async function fetch() {
      setActions(await fetchData(`/actions/`, "admin"));
      setMonitoring(await fetchData(`/users/monitoring/${id}`, roles));
    }
    fetch();
  }, [roles, id]);

  useEffect(() => {
    async function fetchUser() {
      setUser(await fetchData(`/users/${monitoring.user}`, roles));
    }
    fetchUser();
  }, [monitoring, roles]);

  useEffect(() => {
    const sortedCategories: Record<
      string,
      Array<{ id: string; name: string; time: number }>
    > = {};

    actions.forEach((action) => {
      if (!sortedCategories[action.category]) {
        sortedCategories[action.category] = [];
      }
      sortedCategories[action.category].push({
        id: action.id,
        name: action.name,
        time: action.time,
      });
    });

    // Tri des actions dans chaque catégorie par nom
    for (const category in sortedCategories) {
      sortedCategories[category].sort((a, b) => a.name.localeCompare(b.name));
    }

    setSortedActions(sortedCategories);
  }, [actions]);

  useDocumentTitle(`${user.surname} ${user.name} - Modification d'un suivi`);

  useEffect(() => {
    const selectedAction = actions.find((a) => a.id === monitoring.action);
    setMonitoring((prevState) => ({
      ...prevState,
      time: selectedAction ? selectedAction.time : 0,
    }));
  }, [monitoring.action, actions]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    monitoring.status = monitoring.status ? monitoring.status : statusOption[0];
    if (monitoring.time && monitoring.date) {
      setIsLoading(true);
      try {
        await putData(`/users/monitoring/${id}`, monitoring, roles);
        localStorage.setItem("mid", id);
        localStorage.setItem("uid", String(user.id));
        setUpdate("Mise à jour effectuée");
      } catch (error) {
        setUpdate(
          "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
        );
      }
      setIsLoading(false);
    } else setUpdate("");
    if (!monitoring.time) {
      setError((prevError) => ({
        ...prevError,
        time: "Veuillez saisir une durée s'il vous plait",
      }));
    } else if (!monitoring.date) {
      setError((prevError) => ({
        ...prevError,
        date: "Veuillez saisir une date s'il vous plait",
      }));
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setMonitoring({ ...monitoring, [name]: value });
    if (name === "time") {
      setError((prevError) => ({
        ...prevError,
        time:
          value.trim() !== ""
            ? ""
            : "Veuillez saisir une durée s'il vous plait",
      }));
    } else if (name === "date") {
      setError((prevError) => ({
        ...prevError,
        date:
          value.trim() !== "" ? "" : "Veuillez saisir une date s'il vous plait",
      }));
    } else if (name === "action") {
      setError((prevError) => ({
        ...prevError,
        time: "",
      }));
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    setOpenDialog(false);
    try {
      await deleteData(`/users/monitoring/delete/${id}`, roles);
      navigate(`/user/m/${monitoring.user}`);
    } catch (error) {
      setIsLoading(false);
      setUpdate(
        "Impossible de supprimer le suivi, veuillez réessayer ou contacter un administrateur"
      );
    }
  };

  const defaultAction = actions.length > 0 ? actions[0].id : "";
  const action = actions.find((a) => a.id === monitoring.action)
    ? monitoring.action
    : defaultAction;

  const defaultStatus = statusOption.length > 0 ? statusOption[0] : "";
  const status = statusOption.find((a) => a === monitoring.status)
    ? monitoring.status
    : defaultStatus;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {update}
      <a href={monitoring.user ? `/user/m/${monitoring.user}` : `/`}>
        <h3>Retourner aux suivis</h3>
      </a>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "3vh",
          width: "100vw",
          height: "80vh",
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ScrollBar>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "3vh",
              width: "100%",
              paddingTop: "3vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "3vh",
                width: "40vw",
                paddingTop: "3vh",
              }}
            >
              <TextField
                label="Date de l'entretien"
                name="date"
                type="date"
                value={
                  monitoring.date
                    ? moment(monitoring.date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(error.date)}
                helperText={error.date}
              />
              <SearchOptions
                handleChange={handleChange}
                action={action}
                sortedActions={sortedActions}
              />
              <TextField
                label="Commentaire"
                name="remark"
                multiline
                value={monitoring.remark}
                onChange={handleChange}
              />
              <TextField
                label="Durée"
                name="time"
                type="number"
                value={monitoring.time}
                onChange={handleChange}
                error={Boolean(error.time)}
                helperText={error.time}
              />
              <TextField
                select
                label="Statut"
                name="status"
                value={status}
                onChange={handleChange}
              >
                {statusOption.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "5vw",
                }}
              >
                {isLoading ? (
                  <Spinner forButton={true} putAbsolute={false} />
                ) : (
                  <>
                    <Button type="submit">Mettre à jour un suivi</Button>
                    <Button color="secondary" onClick={handleDialogOpen}>
                      Supprimer
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </ScrollBar>
      </form>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmer la suppression"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir supprimer ce suivi ? Cette action est
            irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditMonitoring;
