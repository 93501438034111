export default function Clouds() {
  return (
    <>
      <div
        style={{
          position: "fixed",
          backgroundColor: "yellow",
          height: "11vw",
          width: "11vw",
          bottom: "-5vw",
          right: "-5vw",
          borderRadius: "99999px",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "yellow",
          height: "7vw",
          width: "15vw",
          bottom: "-4vw",
          right: "-4vw",
          borderTopLeftRadius: "999999px",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "yellow",
          height: "15vw",
          width: "7vw",
          bottom: "-4vw",
          right: "-4vw",
          borderTopLeftRadius: "999999px",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#024873",
          height: "11vw",
          width: "11vw",
          bottom: "-5vw",
          left: "-5vw",
          borderRadius: "99999px",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#024873",
          height: "7vw",
          width: "15vw",
          bottom: "-4vw",
          left: "-4vw",
          borderTopRightRadius: "999999px",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#024873",
          height: "15vw",
          width: "7vw",
          bottom: "-4vw",
          left: "-4vw",
          borderTopRightRadius: "999999px",
        }}
      ></div>
    </>
  );
}