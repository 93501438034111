import { BrowserRouter, NavLink } from "react-router-dom";
import "./App.css";
import { AppBar, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import RoutesList from "./utils/Routes";
import Cookies from "js-cookie";
import { ReactComponent as KooshiiWhite } from "./images/kooshii_white.svg";
import { ReactComponent as Account } from "./images/my-account.svg";
import { ReactComponent as Stats } from "./images/stats.svg";
import { navLink } from "./utils/functions/themes";
import { getExpirationCookies } from "./utils/functions/expirationCookies";
import { useEffect, useState } from "react";
import { logout } from "./utils/functions/logout";
import isAdmin from "./utils/functions/isAdmin";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [showOptions, setShowOptions] = useState(false);
  let hideTimeout;

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", updateIsMobile);

    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  useEffect(() => {
    Cookies.set("isMobile", String(isMobile));
  }, [isMobile]);

  return (
    <BrowserRouter>
      {getExpirationCookies(Cookies.get("token")) > 0 &&
      Cookies.get("isMobile") === "false" ? (
        <div
          style={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            paddingTop: "1vh",
            position: "fixed",
            height: "15vh",
            alignItems: "center",
            padding: "1vh 0",
            zIndex: "10",
          }}
        >
          <AppBar
            style={{
              width: "99vw",
              height: "15vh",
              borderRadius: "1vw",
              position: "relative",
              backgroundColor: "#024873",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "15vh",
                flexWrap: "wrap",
                margin: 0,
                padding: "0 1vw",
              }}
            >
              <Link to="/users" style={{ marginRight: "4vw" }}>
                <Typography
                  style={{
                    display: "flex",
                    width: "10vw",
                    alignItems: "center",
                  }}
                >
                  <KooshiiWhite
                    title="Accueil"
                    style={{
                      width: "100%",
                    }}
                  />
                </Typography>
              </Link>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: "100%",
                  justifyContent: "space-evenly",
                  textAlign: "center",
                  columnGap: "1vw",
                  flexWrap: "wrap",
                }}
              >
                <NavLink
                  className={(navData) => (navData.isActive ? "active" : "")}
                  style={navLink}
                  to="/home"
                >
                  Accueil
                </NavLink>
                <NavLink
                  className={(navData) => (navData.isActive ? "active" : "")}
                  style={navLink}
                  to="/users"
                >
                  Gestion des bénéficiaires
                </NavLink>
                <NavLink
                  className={(navData) => (navData.isActive ? "active" : "")}
                  style={navLink}
                  to="/institutes"
                >
                  Gestion des établissements
                </NavLink>
                {isAdmin() ? (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        position: "relative",
                      }}
                      onClick={() => setShowOptions(!showOptions)} // Inverser l'état au clic
                      onMouseEnter={() => {
                        setShowOptions(true);
                        clearTimeout(hideTimeout);
                      }}
                      onMouseLeave={() => {
                        // Utiliser un délai pour masquer la liste après la sortie du survol
                        hideTimeout = setTimeout(() => {
                          setShowOptions(false);
                        }, 100); // Vous pouvez ajuster le délai en millisecondes selon vos préférences
                      }}
                    >
                      Gestion admin
                      {showOptions && (
                        <ul
                          style={{
                            position: "absolute",
                            top: "25px",
                            left: "-38%",
                            backgroundColor: "#024873",
                            padding: "10px",
                            width: "12vw",
                            listStyle: "none",
                            zIndex: 1000,
                            borderBottomLeftRadius: "1vw",
                            borderBottomRightRadius: "1vw",
                          }}
                        >
                          {/* Ajoutez ici les différents éléments de la liste */}
                          <li
                            style={{
                              margin: "5px 0",
                              color: "#fff",
                            }}
                          >
                            <NavLink
                              className={(navData) =>
                                navData.isActive ? "active" : ""
                              }
                              style={navLink}
                              to="/actions"
                            >
                              Gestion des atelier
                            </NavLink>
                          </li>
                          <li
                            style={{
                              margin: "5px 0",
                              color: "#fff",
                            }}
                          >
                            <NavLink
                              className={(navData) =>
                                navData.isActive ? "active" : ""
                              }
                              style={navLink}
                              to="/agencies"
                            >
                              Gestion des agences
                            </NavLink>
                          </li>
                          <li
                            style={{
                              margin: "5px 0",
                              color: "#fff",
                            }}
                          >
                            <NavLink
                              className={(navData) =>
                                navData.isActive ? "active" : ""
                              }
                              style={navLink}
                              to="/employees"
                            >
                              Gestion des permanents
                            </NavLink>
                          </li>
                          <li
                            style={{
                              margin: "5px 0",
                              color: "#fff",
                            }}
                          >
                            <NavLink
                              className={(navData) =>
                                navData.isActive ? "active" : ""
                              }
                              style={navLink}
                              to="/funders"
                            >
                              Gestion des financeurs
                            </NavLink>
                          </li>
                          {/* ... Ajoutez d'autres options ici ... */}
                        </ul>
                      )}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      columnGap: "1vw",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Link to="/my-account">
                      <Typography
                        style={{
                          display: "flex",
                          width: "3vw",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Account
                          title="Mon compte"
                          style={{
                            width: "80%",
                          }}
                        />
                      </Typography>
                    </Link>
                    <Link to="/stats">
                      <Typography
                        style={{
                          display: "flex",
                          width: "3vw",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Stats
                          title="Stats"
                          style={{
                            width: "80%",
                          }}
                        />
                      </Typography>
                    </Link>
                  </div>
                  <a href="" style={navLink} onClick={logout}>
                    Déconnexion
                  </a>
                </div>
              </div>
            </div>
          </AppBar>
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "83vh",
          paddingTop:
            localStorage.getItem("redirectPath") !== "login" &&
            getExpirationCookies(Cookies.get("token")) > 0 &&
            Cookies.get("isMobile") === "false"
              ? "17vh"
              : "0",
          background: "linear-gradient(-45deg, #ffffff 50%, #ffe0b2 100%)",
        }}
      >
        <RoutesList />
      </div>
    </BrowserRouter>
  );
}

export default App;
