import { ReactComponent as Arrow } from "images/arrow.svg";
import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import useDocumentTitle, { fetchData } from "utils/functions/service";
import Cookies from "js-cookie";
import Clouds from "utils/clouds/Clouds";
import ScrollBar from "utils/scrollbar/ScrollBar";
import Spinner from "utils/spinner/Spinner";
import { ShowFile } from "entity/user/monitoring/ShowFiles";

function MonitoringsList({ id }) {
  const [isLoading, setIsLoading] = useState(true);
  const [monitorings, setMonitorings] = useState<
    Array<{
      id: string;
      date: Date;
      remark: string;
      employee: { surname; name };
      action: { name };
      time: number;
      status: string;
      files: string[];
    }>
  >([]);
  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState({
    name: "",
    surname: "",
    agency: 0,
  });
  const [fileIndex, setFileIndex] = useState(null);
  const [monitoringId, setMonitoringId] = useState(null);
  const roles = Cookies.get("roles");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const monitoringRef = useRef(null);
  const [monitoringRefId, setMonitoringRefId] = useState(0);

  useEffect(() => {
    async function fetch() {
      setMonitorings(await fetchData(`/users/monitorings/${id}`, "admin"));
      setUser(await fetchData(`/users/${id}`, roles));
    }
    fetch();
  }, [id, roles]);

  useEffect(() => {
    const storedMonitoringRefId = localStorage.getItem("mid") || "0";
    setMonitoringRefId(parseInt(storedMonitoringRefId));
  }, []);

  useEffect(() => {
    if (monitorings) {
      showRef();
      setIsLoading(false);
    }
  }, [monitorings]);

  useDocumentTitle(`${user.surname} ${user.name} - Suivis de recrutement`);

  const showRef = () => {
    if (monitoringRefId !== 0 && monitoringRef) {
      const parentDiv = monitoringRef.current.offsetParent.parentNode;
      const yPosition = monitoringRef.current.offsetTop;

      parentDiv.scrollTo({
        top: yPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        overflowY: "auto",
        height: "69vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <a
        style={{
          fontSize: "1vw",
          marginBottom: "4vh",
          paddingLeft: "2vw",
          color: "#203864",
          fontWeight: "700",
        }}
        href={`/user/monitorings/new/${id}`}
      >
        Créer un nouveau suivi
      </a>
      {isLoading ? (
        <Spinner showFirstText={false} />
      ) : (
        <ScrollBar>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              rowGap: "1vh",
              marginBottom: "3vh",
            }}
          >
            <div
              style={{
                position: "absolute",
                borderLeft: "0.3vw solid #024873",
                height: "100%",
                left: "50%",
                right: "50%",
              }}
            />
            {monitorings.map((monitoring, index) => (
              <div
                key={monitoring.id}
                ref={
                  monitoring.id == String(monitoringRefId)
                    ? monitoringRef
                    : null
                }
                style={{
                  ...(index % 2 === 0
                    ? { marginRight: "50vw" }
                    : { marginLeft: "50vw" }),
                  backgroundColor: "#F1E511",
                  // ...(index % 2 === 0 ? { background: "linear-gradient(90deg, #fffdde 0%, #c6cdff 100%" } : { background: "linear-gradient(90deg, #c6cdff 0%, #fffdde 100%" }),
                  padding: "2vh 1vw",
                  borderRadius: "0.6vw",
                  display: "flex",
                  width: "40%",
                  maxWidth: "20vw",
                  alignItems: "center",
                  position: "relative",
                  color: "black",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "14vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    ...(index % 2 === 0
                      ? {
                          right: "-14vw",
                          transform: "rotate(180deg) scaleY(0.7)",
                        }
                      : { left: "-14vw", transform: "scaleY(0.7)" }),
                  }}
                >
                  <Arrow />
                </div>
                <div>
                  {/*date*/}
                  <div
                    style={{
                      color: "black",
                      fontWeight: "800",
                      marginBottom: "1vh",
                      alignSelf: "flex-start",
                    }}
                  >
                    {monitoring.date
                      ? moment(monitoring.date).format("DD MMM YYYY")
                      : ""}{" "}
                    - créé par {monitoring?.employee?.surname}{" "}
                    {monitoring?.employee?.name} [{monitoring.status}]
                  </div>
                  {/*remark*/}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Commentaire :
                    </span>
                    <pre style={{ whiteSpace: "pre-line" }}>
                      {monitoring.remark}
                    </pre>
                  </div>
                  {/*time*/}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Durée :
                    </span>
                    {monitoring.time} min
                  </div>
                  {/*action*/}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Nom de l'atelier :
                    </span>
                    {monitoring.action.name}
                  </div>
                  {/*add or see files*/}
                  <ShowFile id={monitoring.id} />
                  {/*update button*/}
                  <Button
                    style={{
                      color: "#024873",
                      fontWeight: "900",
                    }}
                    component="a"
                    href={`/user/monitoring/${monitoring.id}`}
                  >
                    Modifier
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </ScrollBar>
      )}
      <Clouds />
    </div>
  );
}

export default MonitoringsList;
