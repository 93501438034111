import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { buttonDelete } from "./functions/themes";
import { useState } from "react";

interface DeleteButtonProps {
  handleDelete: (closeModal: () => void) => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ handleDelete }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <div>
      <Button style={buttonDelete} color="secondary" onClick={openDeleteDialog}>
        Supprimer
      </Button>
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Confirmation de la suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cet élément ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Annuler
          </Button>
          <Button
            onClick={() => handleDelete(closeDeleteDialog)}
            color="secondary"
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteButton;
