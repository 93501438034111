const colors = {
  yellow: "#F1E511",
  duckBlue: "#024873",
  blue: "#8FAADC",
  darkBlue: "#2F5597",
  darkerBlue: "#203864",
  white: "#FFFFFF",
};

const consts = {
  borderRadius: "0.6vw",
};

export const buttonLogin = {
  backgroundColor: `${colors.duckBlue}`,
  color: "white",
  padding: "2vh 0.4vw",
  borderRadius: "2vh",
  fontSize: "1.2vw",
};

export const inputLogin = {
  border: `0.1vw solid ${colors.duckBlue}`,
  borderRadius: consts.borderRadius,
  placeHolder: colors.white,
};

export const labelLogin = {
  backgroundColor: "none",
};

export const itemList = {
  width: "20vw",
  padding: "1.7vh 0.4vw",
  backgroundColor: colors.blue,
  borderRadius: consts.borderRadius,
  color: colors.white,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1vw",
};

export const selectedItem = {
  color: "yellow",
  backgroundColor: colors.duckBlue,
};

export const buttonNew = {
  border: `0.1vw solid ${colors.darkBlue}`,
  borderRadius: consts.borderRadius,
  color: colors.duckBlue,
  padding: "1.3vh 1.3vw",
  fontSize: "0.8vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "2vh",
  width: "10vw",
};

export const buttonUpdate = {
  color: colors.darkerBlue,
  backgroundColor: colors.blue,
  borderRadius: consts.borderRadius,
  padding: "1vh 0.3vw",
  fontSize: "0.6vw",
};

export const buttonDelete = {
  color: colors.white,
  backgroundColor: colors.darkBlue,
  borderRadius: consts.borderRadius,
  padding: "1vh 0.3vw",
  fontSize: "0.6vw",
};

export const inputField = {
  width: "20vw",
  "& .MuiOutlinedInputInput": {
    fontSize: "1vw",
    border: "0.1vw solid #203864",
  },
  "& .MuiInputLabelRoot": {
    backgroundColor: "#FFF",
    color: "#203864",
    padding: "0 0.4vw",
  },
};

export const labelField = {
  color: colors.darkerBlue,
  fontSize: "1vw",
};

export const titleField = {
  color: colors.darkerBlue,
  fontWeight: 900,
  fontSize: "1.4vw",
};

export const navLink = {
  color: colors.white,
  fontSize: "1vw",
  flexBaxis: "8vw",
  cursor: "pointer",
  width: "12vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const focusStyle = {
  outline: "none",
  boxShadow: "0 0 0 2px white, 0 0 0 5px #024873",
};

export const userFolder = {
  backgroundColor: colors.darkBlue,
  color: colors.white,
  border: `0.1vw solid black`,
  fontSize: "1.4vw",
  fontWeight: 900,
  padding: "0.5vh 1.5vw",
  cursor: "pointer",
};
