import { Button, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDocumentTitle, {
  deleteData,
  fetchData,
  putData,
  postData,
} from "utils/functions/service";
import Cookies from "js-cookie";
import { buttonUpdate, inputField, labelField } from "utils/functions/themes";
import isAdmin from "utils/functions/isAdmin";
import Spinner from "utils/spinner/Spinner";
import DeleteButton from "utils/DeleteButton";

function Agency({ id, updateAgencies, deleteAgencies, mode }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [agency, setAgency] = useState({ name: "" });
  const [employeesAgency, setEmployeesAgency] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [update, setUpdate] = useState("");
  const [error, setError] = useState("");
  const roles = Cookies.get("roles");

  useEffect(() => {
    setError("");
    setUpdate("");
    async function fetchEmployees() {
      setEmployees(await fetchData(`/employees`, roles));
    }
    async function fetch() {
      setAgency(await fetchData(`/agencies/${id}`, roles));
      setEmployeesAgency(await fetchData(`/employees/agency/${id}`, roles));
    }
    if (mode === "update") {
      fetch();
    } else {
      setAgency({ name: "" });
      setEmployeesAgency([]);
    }
    fetchEmployees();
  }, [mode, id, roles]);

  useDocumentTitle(
    `${mode === "update" ? agency.name + " - Modification" : "Nouvelle agence"}`
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (agency.name) {
      setIsLoading(true);
      try {
        const employeeIds = await employeesAgency.map((ea) => ea.employee_id);
        if (mode === "create") {
          let result = await postData(`/agencies`, { ...agency }, roles);
          await putData(
            `/employees/agency/${result.data.id}`,
            employeeIds,
            roles
          );
          navigate("/agencies");
        } else {
          await putData(`/agencies/${id}`, { ...agency }, roles);
          await putData(`/employees/agency/${id}`, employeeIds, roles);
          setUpdate("Mise à jour effectuée");
          updateAgencies();
        }
      } catch (error) {
        setUpdate(
          "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
        );
      }
      setIsLoading(false);
    } else setUpdate("");
  };

  const handleChange = (event) => {
    setAgency({ ...agency, [event.target.name]: event.target.value });
    if (event.target.name === "name") {
      setError(
        event.target.value.trim() !== ""
          ? ""
          : "Veuillez saisir un nom s'il vous plait"
      );
    }
  };

  const handleEmployeeChange = (event, index) => {
    const newEmployees = [...employeesAgency];
    newEmployees[index] = {
      ...newEmployees[index],
      employee_id: event.target.value,
    };
    setEmployeesAgency(newEmployees);
  };

  const handleAddEmployee = () => {
    setEmployeesAgency([
      ...employeesAgency,
      { employee_id: employees[0]?.id || "" },
    ]);
  };

  const handleRemoveEmployee = (index) => {
    const newEmployees = [...employeesAgency];
    newEmployees.splice(index, 1);
    setEmployeesAgency(newEmployees);
  };

  const handleDelete = async (closeModal: () => void) => {
    setIsLoading(true);
    try {
      await deleteData(`/agencies/delete/${id}`, roles);
      deleteAgencies(id);
      closeModal();
    } catch (error) {
      setUpdate(
        "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ paddingTop: "2vh" }}>
      {update}
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "2vh",
          alignItems: "center",
        }}
      >
        <TextField
          label="Nom de l'agence"
          name="name"
          value={agency.name}
          onChange={handleChange}
          error={Boolean(error)}
          helperText={error}
          sx={inputField}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: "3vh",
          }}
        >
          <span style={labelField}>
            {employeesAgency.length ? "E" : "Aucun(es) e"}mployé(es)
            référent(es)
          </span>
          {employeesAgency.map((employeeAgency, index) => (
            <div
              style={{
                display: "flex",
                border: "0.1vw solid #203864",
                padding: "0.5vh 0.3vw",
                flexDirection: "column",
                width: "15vw",
                color: "#203864",
                alignItems: "flex-start",
              }}
              key={index}
            >
              <TextField
                select
                variant="standard"
                label="Employé"
                fullWidth
                value={employeeAgency.employee_id}
                sx={{
                  "& .MuiInputBase-root::before": {
                    border: "none",
                  },
                  "& .MuiSelect-icon": {
                    display: "none",
                  },
                }}
                onChange={(e) => handleEmployeeChange(e, index)}
              >
                {employees.map((employee) => (
                  <MenuItem
                    key={employee.id}
                    value={employee.id}
                    selected={employeeAgency.id === employee.id}
                  >
                    {`${employee.surname} ${employee.name}`}
                  </MenuItem>
                ))}
              </TextField>
              {employees.map(
                (employee) =>
                  employee.id === employeeAgency.employee_id && (
                    <div style={{ fontSize: "0.7vw" }} key={employee.id}>
                      {employee.email} <br />
                      {employee.phone}
                    </div>
                  )
              )}
              <Button
                style={{
                  textTransform: "none",
                  alignSelf: "center",
                  cursor: "pointer",
                  marginTop: "1vh",
                }}
                onClick={() => handleRemoveEmployee(index)}
              >
                Supprimer
              </Button>
            </div>
          ))}
          <Button onClick={handleAddEmployee}>Ajouter un employé</Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "5vw",
          }}
        >
          {isLoading ? (
            <Spinner forButton={true} putAbsolute={false} />
          ) : (
            <>
              <Button style={buttonUpdate} type="submit">
                {mode === "create"
                  ? "Créer une agence"
                  : "Mettre à jour l'agence"}
              </Button>
              {mode === "update" && isAdmin() && (
                <DeleteButton handleDelete={handleDelete} />
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
}

export default Agency;
