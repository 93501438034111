import { Button, MenuItem, TextField } from "@mui/material";
import moment from "moment";
import "moment/locale/fr";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDocumentTitle, { fetchData, postData } from "utils/functions/service";
import Cookies from "js-cookie";
import Spinner from "utils/spinner/Spinner";
import { SearchOptions } from "entity/user/monitoring/SearchOptions";
import { MonitoringStatus } from "utils/functions/enums";
import ScrollBar from "utils/scrollbar/ScrollBar";

function NewMonitoring() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [actions, setActions] = useState<
    Array<{ id: string; name: string; category: string; time: number }>
  >([]);
  const [sortedActions, setSortedActions] = useState<
    Record<string, Array<{ id: string; name: string; time: number }>>
  >({});
  const [files, setFiles] = useState([]);
  const [monitoring, setMonitoring] = useState({
    date: new Date(),
    remark: "",
    time: 0,
    status: "",
    action: "",
    employee: 0,
  });
  const [update, setUpdate] = useState("");
  const [error, setError] = useState({
    date: "",
    time: "",
  });
  const [user, setUser] = useState({ name: "", surname: "" });
  const roles = Cookies.get("roles");

  const statusOption = Object.values(MonitoringStatus);

  useEffect(() => {
    async function fetch() {
      setActions(await fetchData(`/actions/`, "admin"));
      setUser(await fetchData(`/users/${id}`, roles));
    }
    fetch();
  }, [id, roles]);

  useDocumentTitle(
    `${user.surname} ${user.name} - Nouveau suivi de recrutement`
  );

  useEffect(() => {
    const sortedCategories: Record<
      string,
      Array<{ id: string; name: string; time: number }>
    > = {};

    actions.forEach((action) => {
      if (!sortedCategories[action.category]) {
        sortedCategories[action.category] = [];
      }
      sortedCategories[action.category].push({
        id: action.id,
        name: action.name,
        time: action.time,
      });
    });

    // Tri des actions dans chaque catégorie par nom
    for (const category in sortedCategories) {
      sortedCategories[category].sort((a, b) => a.name.localeCompare(b.name));
    }

    setSortedActions(sortedCategories);
  }, [actions]);

  useEffect(() => {
    const selectedAction = actions.find((a) => a.id === monitoring.action);
    setMonitoring((prevState) => ({
      ...prevState,
      time: selectedAction ? selectedAction.time : 0,
    }));
  }, [monitoring.action, actions]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    monitoring.employee = Cookies.get("userid");
    monitoring.action = monitoring.action ? monitoring.action : actions[0].id;
    monitoring.time = monitoring.time ? monitoring.time : actions[0].time;
    monitoring.status = monitoring.status ? monitoring.status : statusOption[0];
    if (monitoring.time && monitoring.date) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append(`files`, files[i]);
        }
        const newMonitoring = await postData(
          `/users/monitorings/${id}`,
          monitoring,
          roles
        );
        await postData(
          `/users/monitoring/${newMonitoring.data}/files`,
          formData,
          roles
        );
        navigate(`/user/m/${id}`);
      } catch (error) {
        setIsLoading(false);
        setUpdate(
          "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
        );
      }
    }
    if (!monitoring.time) {
      setError((prevError) => ({
        ...prevError,
        time: "Veuillez saisir une durée s'il vous plait",
      }));
    } else if (!monitoring.date) {
      setError((prevError) => ({
        ...prevError,
        date: "Veuillez saisir une date s'il vous plait",
      }));
    }
  };
  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "files[]") {
      setFiles(Array.from(files));
    } else setMonitoring({ ...monitoring, [name]: value });

    if (name === "time") {
      setError((prevError) => ({
        ...prevError,
        time:
          value.trim() !== ""
            ? ""
            : "Veuillez saisir une durée s'il vous plait",
      }));
    } else if (name === "date") {
      setError((prevError) => ({
        ...prevError,
        date:
          value.trim() !== "" ? "" : "Veuillez saisir une date s'il vous plait",
      }));
    } else if (name === "action") {
      setError((prevError) => ({
        ...prevError,
        time: "",
      }));
    }
  };

  const defaultActionId = actions.length > 0 ? actions[0].id : "";
  const selectedAction = actions.find((a) => a.id === monitoring.action);
  const action = selectedAction ? selectedAction.id : defaultActionId;

  const time = monitoring.time
    ? monitoring.time
    : actions.length > 0
    ? actions[0]?.time
    : 0;

  const defaultStatus = statusOption.length > 0 ? statusOption[0] : "";
  const status = statusOption.find((a) => a === monitoring.status)
    ? monitoring.status
    : defaultStatus;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {update}
      <a href={`/user/m/${id}`}>
        <h3>Retourner aux suivis</h3>
      </a>
      <form
        encType="multipart/form-data"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "3vh",
          width: "100vw",
          height: "80vh",
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ScrollBar>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "3vh",
              width: "100%",
              paddingTop: "3vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "3vh",
                width: "40vw",
                paddingTop: "3vh",
              }}
            >
              <TextField
                label="Date de l'entretien"
                name="date"
                type="date"
                value={
                  monitoring.date
                    ? moment(monitoring.date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(error.date)}
                helperText={error.date}
              />
              <SearchOptions
                handleChange={handleChange}
                action={action}
                sortedActions={sortedActions}
              />

              <TextField
                label="Commentaire"
                name="remark"
                multiline
                value={monitoring.remark}
                onChange={handleChange}
              />
              <TextField
                label="Durée"
                name="time"
                type="number"
                value={time}
                onChange={handleChange}
                error={Boolean(error.time)}
                helperText={error.time}
              />
              <TextField
                select
                label="Statut"
                name="status"
                value={status}
                onChange={handleChange}
              >
                {statusOption.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <input
                multiple
                type="file"
                name="files[]"
                onChange={handleChange}
              />
              {isLoading ? (
                <Spinner forButton={true} putAbsolute={false} />
              ) : (
                <Button type="submit">Créer un suivi</Button>
              )}
            </div>
          </div>
        </ScrollBar>
      </form>
    </div>
  );
}

export default NewMonitoring;
