import {
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  Checkbox,
  TextField,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

export const SearchFunders = ({ funders, selectedFunders, handleChange }) => {
  const [searchText, setSearchText] = useState("");
  console.log(selectedFunders);
  const normalizeAndSplitSearchText = (str) =>
    str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .split(/\s+/)
      .filter((word) => word !== "");

  const normalizeStringForSearch = (str) =>
    str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  return (
    <>
      <Select
        name="funder"
        multiple
        value={selectedFunders.map((funder) => funder.funder_id)}
        onChange={handleChange}
        MenuProps={{ autoFocus: false }}
        onClose={() => setSearchText("")}
        renderValue={(selectedIds) => {
          if (selectedIds.length === 0) {
            return <span style={{ color: "grey" }}>Choisir un financeur</span>;
          } else if (selectedIds.length === 1) {
            const funderName = funders.find(
              (funder) => funder.id === selectedIds[0]
            )?.name;
            return <span>{funderName}</span>;
          } else {
            const firstFunderName = funders.find(
              (funder) => funder.id === selectedIds[0]
            )?.name;
            return <span>{`${firstFunderName}, ...`}</span>;
          }
        }}
      >
        {funders.map((funder) => {
          const { id, name } = funder;
          const normalizedSearchText = normalizeStringForSearch(searchText);
          const normalizedName = normalizeStringForSearch(name);
          const normalizedSearchTextArray =
            normalizeAndSplitSearchText(searchText);
          const normalizedNameArray = normalizeAndSplitSearchText(name);
          const isMatch =
            normalizedName.includes(normalizedSearchText) ||
            normalizedNameArray.some((word) =>
              normalizedSearchTextArray.includes(word)
            );
          const isFunderSelected = selectedFunders.some(
            (selected) => selected.funder_id === id
          );

          return (
            isMatch && (
              <MenuItem key={id} value={id}>
                <Checkbox
                  checked={isFunderSelected}
                  className={
                    isFunderSelected ? "checkedCheckbox" : "uncheckedCheckbox"
                  }
                />
                {name}
              </MenuItem>
            )
          );
        })}
      </Select>
    </>
  );
};
