import { useEffect, useState, useCallback } from "react";
import useDocumentTitle, { fetchData } from "utils/functions/service";
import {
  buttonNew,
  focusStyle,
  itemList,
  selectedItem,
} from "utils/functions/themes";
import Cookies from "js-cookie";
import Agency from "./Agency";
import { useLocation } from "react-router-dom";
import Clouds from "utils/clouds/Clouds";
import ScrollBar from "utils/scrollbar/ScrollBar";
import Spinner from "utils/spinner/Spinner";

function AgenciesList() {
  const [isLoading, setIsLoading] = useState(true);
  const [agencies, setAgencies] = useState<Array<{ id: string; name: string }>>(
    []
  );
  const [numAgency, setNumAgency] = useState(null);
  const [focus, setFocus] = useState(null);
  const roles = Cookies.get("roles");
  const location = useLocation();

  useDocumentTitle(`Liste des agences`);

  const updateAgencies = async () => {
    setAgencies(await fetchData(`/agencies`, roles));
  };

  const deleteAgencies = async (id:number) => {
    setNumAgency(null);
    let newAgencies = await fetchData(`/agencies`, roles);
    setAgencies(newAgencies.filter((a) => a.id !== id));
  };

  useEffect(() => {
    setNumAgency(null);
    async function fetch() {
      setAgencies(await fetchData(`/agencies`, roles));
    }
    fetch();
  }, [location, roles]);

  useEffect(() => {
    if (agencies.length > 0) setIsLoading(false);
  }, [agencies]);
  
  const handleKeyDown = useCallback((event, employeeId, fcnt) => {
    if (event.which === 13 || event.which === 32) {
      fcnt(employeeId);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "80vh",
        overflow: "hidden",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "48vw" }}>
        <ScrollBar>
          <div
            style={{
              rowGap: "3vh",
              paddingTop: "4vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              tabIndex={0}
              style={buttonNew}
              onClick={() => setNumAgency(0)}
              onKeyDown={(event) => handleKeyDown(event, 0, setNumAgency)}
            >
              Nouvelle agence
            </div>
            {isLoading ? (
              <Spinner showFirstText={false} />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  rowGap: "2vh",
                }}
              >
                {agencies
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((agency) => (
                    <div
                      tabIndex={0}
                      onFocus={() => setFocus(agency.id)}
                      onBlur={() => setFocus(null)}
                      key={agency.id}
                      onClick={() => setNumAgency(agency.id)}
                      onKeyDown={(event) =>
                        handleKeyDown(event, agency.id, setNumAgency)
                      }
                      style={
                        numAgency && numAgency === agency.id
                          ? Object.assign({}, itemList, selectedItem)
                          : focus === agency.id
                          ? Object.assign(
                              {},
                              itemList,
                              selectedItem,
                              focusStyle
                            )
                          : itemList
                      }
                    >
                      <div>{agency.name}</div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </ScrollBar>
      </div>
      <div
        style={{
          borderLeft: "0.2vw solid #203864",
          height: "80%",
          marginTop: "4vh",
        }}
      ></div>
      <div
        style={{
          width: "48vw",
          minHeight: "40vh",
        }}
      >
        {numAgency !== null ? (
          <ScrollBar direction="left">
            <Agency
              id={numAgency}
              updateAgencies={updateAgencies}
              deleteAgencies={deleteAgencies}
              mode={numAgency !== 0 ? "update" : "create"}
            />
          </ScrollBar>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1vw",
            }}
          >
            Veuillez sélectionner une agence 😁
          </div>
        )}
      </div>
      <Clouds />
    </div>
  );
}

export default AgenciesList;
