import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDocumentTitle, {
  deleteData,
  fetchData,
  putData,
  postData,
} from "utils/functions/service";
import Cookies from "js-cookie";
import { buttonUpdate, inputField } from "utils/functions/themes";
import isAdmin from "utils/functions/isAdmin";
import Spinner from "utils/spinner/Spinner";
import DeleteButton from "utils/DeleteButton";

function Institute({ id, updateInstitutes, deleteInstitutes, mode }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [institute, setInstitute] = useState({ name: "" });
  const [users, setUsers] = useState<
    Array<{ id: string; name: string; surname: string; status: string }>
  >([]);
  const [update, setUpdate] = useState("");
  const [error, setError] = useState("");
  const roles = Cookies.get("roles");

  useDocumentTitle(
    `${
      mode === "update"
        ? institute.name + " - Modification"
        : "Nouvel établissement"
    }`
  );

  useEffect(() => {
    async function fetch() {
      setInstitute(await fetchData(`/institutes/${id}`, roles));
      setUsers(await fetchData(`/users/institute/${id}`, roles));
    }
    if (mode === "update") {
      fetch();
    } else {
      setInstitute({ name: "" });
      setUsers([]);
    }
  }, [mode, id, roles]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (institute.name) {
      setIsLoading(true);
      try {
        if (mode === "create") {
          await postData(`/institutes`, institute, roles);
          navigate("/institutes");
        } else {
          await putData(`/institutes/${id}`, institute, roles);
          setUpdate("Mise à jour effectuée");
          updateInstitutes();
        }
      } catch (error) {
        setUpdate(
          "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
        );
      }
      setIsLoading(false);
    } else {
      setError("Veuillez saisir un nom s'il vous plait");
    }
  };

  const handleChange = (event) => {
    setInstitute({ ...institute, [event.target.name]: event.target.value });
    if (event.target.name === "name") {
      setError(
        event.target.value.trim() !== ""
          ? ""
          : "Veuillez saisir un nom s'il vous plait"
      );
    }
  };

  const handleDelete = async (closeModal: () => void) => {
    setIsLoading(true);
    try {
      await deleteData(`/institutes/delete/${id}`, roles);
      deleteInstitutes(id);
      closeModal();
    } catch (error) {
      setUpdate(
        "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ paddingTop: "2vh" }}>
      {update}
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "2vh",
          alignItems: "center",
        }}
      >
        <TextField
          label="Nom de l'établissement"
          name="name"
          value={institute.name}
          onChange={handleChange}
          error={Boolean(error)}
          helperText={error}
          sx={inputField}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "5vw",
          }}
        >
          {isLoading ? (
            <Spinner forButton={true} putAbsolute={false} />
          ) : (
            <>
              <Button style={buttonUpdate} type="submit">
                {mode === "create"
                  ? "Créer un établissement"
                  : "Mettre à jour l'établissement"}
              </Button>
              {mode === "update" && isAdmin() && (
                <DeleteButton handleDelete={handleDelete} />
              )}
            </>
          )}
        </div>
      </form>
      Liste des salariés :
      <Box display="flex" flexDirection="column" alignItems="center">
        {users
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((user) => (
            <Grid item xl={5} key={user.id}>
              <Box display="flex" flexDirection="row" columnGap="3vw">
                <div>{user.name}</div>
                <div>{user.surname}</div>
                <div>{user.status}</div>
                <Button component="a" href={`/user/${user.id}`}>
                  Modifier
                </Button>
                <Button component="a" href={`/user/m/${user.id}`}>
                  Voir son suivis
                </Button>
              </Box>
            </Grid>
          ))}
      </Box>
    </div>
  );
}

export default Institute;
