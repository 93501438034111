import { useEffect, useState, useCallback } from "react";
import useDocumentTitle, { fetchData } from "utils/functions/service";
import Cookies from "js-cookie";
import { buttonNew, focusStyle, itemList, selectedItem } from "utils/functions/themes";
import Institute from "../institute/Institute";
import { useLocation } from "react-router-dom";
import Clouds from "utils/clouds/Clouds";
import ScrollBar from "utils/scrollbar/ScrollBar";
import Spinner from "utils/spinner/Spinner";

function InstitutesList() {
  const [isLoading, setIsLoading] = useState(true);
  const [institutes, setInstitutes] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [numInstitute, setNumInstitute] = useState(null);
  const [focus, setFocus] = useState(null);
  const roles = Cookies.get("roles");
  const location = useLocation();

  useDocumentTitle(`Liste des établissements`);

  const updateInstitutes = async () => {
    setInstitutes(await fetchData(`/institutes`, roles));
  };

  const deleteInstitutes = async (id: number) => {
    setNumInstitute(null);
    let newInstitutes = await fetchData(`/institutes`, roles);
    setInstitutes(newInstitutes.filter((a) => a.id !== id));
  };

  useEffect(() => {
    setNumInstitute(null);
    async function fetch() {
      setInstitutes(await fetchData(`/institutes`, roles));
    }
    fetch();
  }, [location, roles]);

  useEffect(() => {
    if (institutes.length > 0) setIsLoading(false);
  }, [institutes]);

  const handleKeyDown = useCallback((event, instituteId, fcnt) => {
    if (event.which === 13 || event.which === 32) {
      fcnt(instituteId);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "80vh",
        overflow: "hidden",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "48vw" }}>
        <ScrollBar>
          <div
            style={{
              rowGap: "3vh",
              paddingTop: "4vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              tabIndex={0}
              style={buttonNew}
              onClick={() => setNumInstitute(0)}
              onKeyDown={(event) => handleKeyDown(event, 0, setNumInstitute)}
            >
              Nouvel établissement
            </div>
            {isLoading ? (
              <Spinner showFirstText={false} />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  rowGap: "2vh",
                }}
              >
                {institutes
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((institute) => (
                    <div
                      tabIndex={0}
                      onFocus={() => setFocus(institute.id)}
                      onBlur={() => setFocus(null)}
                      key={institute.id}
                      onClick={() => setNumInstitute(institute.id)}
                      onKeyDown={(event) =>
                        handleKeyDown(event, institute.id, setNumInstitute)
                      }
                      style={
                        numInstitute && numInstitute === institute.id
                          ? Object.assign({}, itemList, selectedItem)
                          : focus === institute.id
                          ? Object.assign(
                              {},
                              itemList,
                              selectedItem,
                              focusStyle
                            )
                          : itemList
                      }
                    >
                      <div>{institute.name}</div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </ScrollBar>
      </div>
      <div
        style={{
          borderLeft: "0.2vw solid #203864",
          height: "80%",
          marginTop: "4vh",
        }}
      ></div>
      <div
        style={{
          width: "48vw",
          overflowY: "auto",
          minHeight: "40vh",
        }}
      >
        {numInstitute !== null ? (
          <ScrollBar direction="left">
            <Institute
              id={numInstitute}
              updateInstitutes={updateInstitutes}
              deleteInstitutes={deleteInstitutes}
              mode={numInstitute !== 0 ? "update" : "create"}
            />
          </ScrollBar>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1vw",
            }}
          >
            Veuillez sélectionner un établissement 😁
          </div>
        )}
      </div>
      <Clouds />
    </div>
  );
}

export default InstitutesList;
