import axios from "axios";
import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
  useNavigate,
} from "react-router-dom";
import ActionsList from "entity/admin/action/ActionsList";
import AgenciesList from "entity/admin/agency/AgenciesList";
import EmployeesList from "entity/admin/employee/EmployeesList";
import InstitutesList from "entity/institute/InstituteList";
import EditMonitoring from "entity/user/monitoring/EditMonitoring";
import NewMonitoring from "entity/user/monitoring/NewMonitoring";
import UsersList from "entity/user/UsersList";
import LoginPage from "pages/LoginPage";
import NotFoundPage from "pages/NotFoundPage";
import Cookies from "js-cookie";
import { getExpirationCookies } from "./functions/expirationCookies";
import PasswordLost from "my-account/PasswordLost";
import MyAccount from "my-account/MyAccount";
import isAdmin from "./functions/isAdmin";
import Stats from "pages/Stats";
import UserPage from "entity/user/UserPage";
import Spinner from "./spinner/Spinner";
import FundersList from "entity/admin/funder/FundersList";
import HomePage from "pages/Home";

const ProtectedRoute = () => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const roles = Cookies.get("roles");
  const navigate = useNavigate();

  useEffect(() => {
    if (
      getExpirationCookies(Cookies.get("token")) > 0 &&
      Cookies.get("token") !== false
    ) {
      const refreshToken = async () => {
        try {
          const response = await axios.get(
            process.env.REACT_APP_BASE_URL + "/auth/refresh",
            {
              withCredentials: true,
            }
          );
          if (response?.data?.access_token)
            Cookies.set("token", response?.data?.access_token, {
              secure: true,
              sameSite: "none",
              domain: "kooshii.fr",
            });
        } catch (error) {
          throw new Error(error);
        }
      };
      refreshToken();
    }
  }, [location.pathname]);

  useEffect(() => {
    const checkLogged = async () => {
      setIsLoading(true);
      try {
        const logged = await axios.get(
          process.env.REACT_APP_BASE_URL + "/auth/check",
          {
            withCredentials: true,
            headers: {
              roles: roles,
            },
          }
        );
        setIsLoggedIn(logged.data);
      } catch (error) {
        setIsLoggedIn(false);
      } finally {
        setIsLoading(false);
      }
    };
    const expiration = getExpirationCookies(Cookies.get("token"));
    if (expiration && expiration > 0) {
      checkLogged();
    } else {
      navigate("/login");
      window.location.reload();
    }
  }, [roles, location.pathname, navigate]);

  if (isLoading) {
    return <Spinner showFirstText={false} />; // or loading spinner, etc...
  }
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

function RoutesList() {
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname !== "/login" &&
      location.pathname !== localStorage.getItem("redirectPath")
    ) {
      localStorage.removeItem("formData");
      localStorage.setItem("redirectPath", location.pathname);
    }
    if (
      location.pathname !==
      "/user/monitorings/" + localStorage.getItem("uid")
    ) {
      localStorage.setItem("mid", "0");
      localStorage.setItem("uid", "0");
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/resetPassword" element={<PasswordLost />} />
      <Route element={<ProtectedRoute />}>
        {isAdmin() ? (
          <>
            <Route path="/actions" element={<ActionsList />} />
            <Route path="/agencies" element={<AgenciesList />} />
            <Route path="/employees" element={<EmployeesList />} />
            <Route path="/funders" element={<FundersList />} />
          </>
        ) : (
          <></>
        )}
        <Route path="/institutes" element={<InstitutesList />} />
        <Route path="/users" element={<UsersList />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/user/:id" element={<UserPage />} />
        <Route path="/user/m/:id" element={<UserPage monitoring={true} />} />
        <Route path="/user/monitorings/new/:id" element={<NewMonitoring />} />
        <Route path="/user/monitoring/:id" element={<EditMonitoring />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/stats" element={<Stats />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default RoutesList;
