import { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { postData, putData } from "utils/functions/service";
import { ReactComponent as Kooshii } from "images/kooshii.svg";
import { inputField } from "utils/functions/themes";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { labelLogin, inputLogin, labelField, buttonLogin } from "utils/functions/themes";
import * as Icons from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getExpirationCookies } from "utils/functions/expirationCookies";

function PasswordLost() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setToken(searchParams.get("token"));
    if (token) {
      try {
        const decodedToken = jwt_decode(token) as { email: string };
        if (
          decodedToken.email !== "" &&
          decodedToken.email !== "notfound" &&
          getExpirationCookies(token) > 0
        ) {
          // setMessage("");
          setEmail(decodedToken.email);
          setShowPasswordFields(true);
        }
      } catch (error) {
        setMessage("Token invalide");
      }
    }
  });

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    setError("");
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setError("");
  };

  const handleSendResetLink = async () => {
    try {
      await postData(
        "/email/resetPassword",
        {
          email: email,
        },
        "admin",
        false
      );
      setMessage(
        "Un email contenant un lien pour réinitialiser votre mot de passe a été envoyé à votre adresse email."
      );
    } catch (error) {
      setMessage(
        "Une erreur est survenue lors de l'envoi du lien de réinitialisation du mot de passe."
      );
    }
  };

  const handleSavePassword = async () => {
    if (password === confirmPassword && password !== "") {
      try {
        await postData(
          `/employees/${token}/reset`,
          { password: password },
          "admin",
          false
        );
        setMessage(
          "Votre mot de passe a été modifié avec succès. Vous allez être redirigés d'ici quelques secondes.\r\nSi cela ne fonctionne pas vous pouvez cliquer sur <a href='/login'>ce lien</a>"
        );
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } catch (error) {
        setMessage(
          "Une erreur est survenue lors de la modification de votre mot de passe."
        );
      }
    } else setError("Mots de passe différents ou vide");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "10vh",
      }}
    >
      <div
        style={{
          width: "50vw",
          marginBottom: "10vh",
        }}
      >
        <Kooshii />
      </div>
      {!showPasswordFields ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "3vh",
          }}
        >
          <p
            style={{
              fontSize: "1.4vw",
            }}
          >
            Renseigner votre adresse mail, si celle-ci est rattachée à un
            compte, un email vous sera envoyé
          </p>
          <TextField
            InputLabelProps={{ style: labelField }}
            InputProps={{ style: inputField }}
            label="Email"
            placeholder="Ex : jean.dujardin@gmail.com"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            error={Boolean(error)} // ajout de l'erreur
            helperText={error}
          />
          <div style={buttonLogin} onClick={handleSendResetLink}>
            Recevoir le lien
          </div>
          <p>{message}</p>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "3vh",
          }}
        >
          <p>
            Votre adresse mail est bien rattachée à un compte, merci de modifier
            votre mot de passe
          </p>
          <TextField
            label="Mot de passe"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handleChangePassword}
            error={Boolean(error)} // ajout de l'erreur
            helperText={error}
            InputLabelProps={{ style: labelLogin }}
            InputProps={{
              style: { ...inputLogin },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <Icons.Visibility />
                    ) : (
                      <Icons.VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirmez votre mot de passe"
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={handleChangeConfirmPassword}
            error={Boolean(error)} // ajout de l'erreur
            helperText={error}
            InputLabelProps={{ style: labelLogin }}
            InputProps={{
              style: { ...inputLogin },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <Icons.Visibility />
                    ) : (
                      <Icons.VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <button onClick={handleSavePassword}>
            {error}
            Enregistrer le nouveau mot de passe
          </button>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
}

export default PasswordLost;
