import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDocumentTitle, {
  deleteData,
  fetchData,
  putData,
  postData,
} from "utils/functions/service";
import Cookies from "js-cookie";
import { buttonDelete, buttonUpdate, inputField } from "utils/functions/themes";
import * as bcrypt from "bcryptjs";
import DeleteButton from "utils/DeleteButton";
import isAdmin from "utils/functions/isAdmin";
import Spinner from "utils/spinner/Spinner";

function Employee({
  id,
  updateEmployees,
  deleteEmployees,
  mode,
  listEmployees,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
  });
  const [update, setUpdate] = useState("");
  const [error, setError] = useState({
    name: { text: "un nom", value: "" },
    surname: { text: "un prénom", value: "" },
    email: { text: "un email", value: "", double: false },
    phone: { text: "un numéro de téléphone", value: "", double: false },
  });
  const roles = Cookies.get("roles");
  useDocumentTitle(
    `${
      mode === "update"
        ? "Permanent - " +
          employee.surname +
          " " +
          employee.name +
          " - Modification"
        : "Nouveau permanent"
    }`
  );

  useEffect(() => {
    async function fetch() {
      setEmployee(await fetchData(`/employees/${id}`, roles));
    }
    if (mode === "update") {
      fetch();
    } else {
      setEmployee({
        name: "",
        surname: "",
        email: "",
        phone: "",
      });
    }
  }, [mode, id, roles]);

  const requiredFields = {
    name: "un nom",
    surname: "un prénom",
    email: "un email",
    phone: "un numéro de téléphone",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (error.email.double || error.phone.double) {
      setUpdate("");
      return;
    }

    let formIsValid = true;
    let newErrors = {
      name: { text: "un nom", value: "" },
      surname: { text: "un prénom", value: "" },
      email: { text: "un email", value: "", double: false },
      phone: { text: "un numéro de téléphone", value: "", double: false },
    };
    Object.entries(requiredFields).forEach(([fieldName, fieldLabel]) => {
      if (!employee[fieldName]) {
        formIsValid = false;
        newErrors[fieldName] = {
          value: `Veuillez saisir ${fieldLabel} s'il vous plaît`,
          text: requiredFields[fieldName],
        };
      }
    });
    setError(newErrors);

    if (formIsValid) {
      setIsLoading(true);
      try {
        if (mode === "create") {
          await postData(`/employees`, employee, roles);
          navigate("/employees");
        } else {
          await putData(`/employees/${id}`, employee, roles);
          setUpdate("Mise à jour effectuée");
          updateEmployees();
        }
      } catch (error) {
        setUpdate(
          "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
        );
      }
    } else setUpdate("");
    setIsLoading(false);
  };

  const handleChange = (event) => {
    setEmployee({ ...employee, [event.target.name]: event.target.value });
    const { name, value } = event.target;

    if (["name", "surname", "email", "phone"].includes(name)) {
      setError((prevError) => ({
        ...prevError,
        [name]: {
          text: prevError[name].text,
          value:
            value.trim() !== ""
              ? ""
              : `Veuillez saisir ${prevError[name].text} s'il vous plaît`,
          double: false,
        },
      }));
    }

    listEmployees.forEach((employee) => {
      if (value === employee.email && employee.id !== id)
        setError((prevError) => ({
          ...prevError,
          email: {
            text: prevError["email"].text,
            value:
              "Cette adresse mail est déjà utilisée par un autre permanent",
            double: true,
          },
        }));
      if (value === employee.phone && employee.id !== id)
        setError((prevError) => ({
          ...prevError,
          phone: {
            text: prevError["phone"].text,
            value:
              "Cette numéro de téléphone est déjà utilisé par un autre permanent",
            double: true,
          },
        }));
    });

    if (name === "phone") {
      const formattedPhone = value.trim().replace(/[^0-9]/g, ""); // Supprimer tous les caractères non numériques
      const formattedValue = [];

      for (let i = 0; i < formattedPhone.length; i++) {
        formattedValue.push(formattedPhone[i]);

        if ((i + 1) % 2 === 0 && i !== formattedPhone.length - 1) {
          formattedValue.push(" ");
        }
      }

      setEmployee((prevEmployee) => ({
        ...prevEmployee,
        phone: formattedValue.join(""),
      }));
    }
  };

  const resetPassword = async () => {
    setIsLoading(true);
    try {
      let random = (Math.random() + 1).toString(36).substring(2);
      let randomPassword = await bcrypt.hash(random, 10);
      await postData(
        `/email/resetPasswordByAdmin`,
        { email: employee.email },
        roles
      );
      await putData(`/employees/${id}`, { password: "Kooshii76" }, roles);
    } catch (error) {
      setUpdate(
        "Impossible de réinitialiser le mot de passe, veuillez réessayer ou contacter un administrateur"
      );
    }
    setIsLoading(false);
  };

  const handleDelete = async (closeModal: () => void) => {
    setIsLoading(true);
    try {
      await deleteData(`/employees/delete/${id}`, roles);
      deleteEmployees(id);
      closeModal();
    } catch (error) {
      setUpdate(
        "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ paddingTop: "2vh" }}>
      {update}
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "2vh",
          alignItems: "center",
        }}
      >
        <TextField
          label="Nom du permanent"
          name="name"
          value={employee.name}
          onChange={handleChange}
          error={Boolean(error.name.value)}
          helperText={error.name.value}
          sx={inputField}
        />
        <TextField
          label="Prenom du permanent"
          name="surname"
          value={employee.surname}
          onChange={handleChange}
          error={Boolean(error.surname.value)}
          helperText={error.surname.value}
          sx={inputField}
        />
        <TextField
          label="Email du permanent"
          name="email"
          value={employee.email}
          onChange={handleChange}
          error={Boolean(error.email.value)}
          helperText={error.email.value}
          sx={inputField}
        />
        <TextField
          label="Numéro de téléphone du permanent"
          name="phone"
          value={employee.phone}
          onChange={handleChange}
          error={Boolean(error.phone.value)}
          helperText={error.phone.value}
          sx={inputField}
          inputProps={{
            pattern: "[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}", // Utiliser un pattern pour forcer le format XX-XX-XX-XX-XX
            maxLength: 14, // Limiter le nombre de caractères à 14 (pour prendre en compte les tirets)
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "5vw",
          }}
        >
          {isLoading ? (
            <Spinner forButton={true} putAbsolute={false} />
          ) : (
            <>
              <Button style={buttonUpdate} type="submit">
                {mode === "create"
                  ? "Créer le permanent"
                  : "Mettre à jour le permanent"}
              </Button>
              {mode === "update" && (
                <Button style={buttonDelete} onClick={resetPassword}>
                  Réinitialiser le mot de passe
                </Button>
              )}
              {mode === "update" && isAdmin() && (
                <DeleteButton handleDelete={handleDelete} />
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
}

export default Employee;
