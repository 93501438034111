import Cookies from "js-cookie";

export const logout = () => {
  Cookies.remove("userid", {
    domain: "kooshii.fr",
    secure: true,
    sameSite: "none",
  });
  Cookies.remove("token", {
    domain: "kooshii.fr",
    secure: true,
    sameSite: "none",
  });
  Cookies.remove("roles", {
    domain: "kooshii.fr",
    secure: true,
    sameSite: "none",
  });
  window.location.reload();
};
