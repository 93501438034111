import { Button, TextField } from "@mui/material";
import { useState } from "react";
import useDocumentTitle, {
  fetchData,
  postData,
  putData,
} from "utils/functions/service";
import Cookies from "js-cookie";
import bcrypt from "bcryptjs";
import { buttonUpdate, inputField } from "utils/functions/themes";
import { logout } from "utils/functions/logout";

function MyAccount() {
  const [employee, setEmployee] = useState({
    oldPassword: "",
    password: "",
    newPassword: "",
  });
  const [update, setUpdate] = useState("");
  const [error, setError] = useState({
    oldPassword: "",
    password: "",
    newPassword: "",
  });
  const roles = Cookies.get("roles");
  useDocumentTitle(`${"Nouveau permanent"}`);

  const email = async () => {
    try {
      await postData(
        `/email/userUpdate`,
        {
          user: "BENEFICIAIRE TEST",
          employee: "EMPLOYE",
        },
        roles
      );
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email", error);
    }
  };
  if (Cookies.get("userid") == 1) {
    email();
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const someEmployee = await fetchData(
      `/employees/${Cookies.get("userid")}`,
      roles
    );
    let samePassword = await bcrypt.compare(
      employee.oldPassword,
      someEmployee.password
    );

    if (
      employee.oldPassword &&
      employee.password &&
      employee.newPassword &&
      employee.password === employee.newPassword &&
      samePassword
    ) {
      setError({
        oldPassword: "",
        password: "",
        newPassword: "",
      });
      try {
        await putData(
          `/employees/${Cookies.get("userid")}`,
          { password: employee.newPassword },
          roles
        );
        setUpdate("Mot de passe changé, vous allez être déconnecté");
        setTimeout(() => {
          logout();
        }, 1200);
      } catch (error) {
        setUpdate(
          "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
        );
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        oldPassword: "Les mots de passe saisis sont incorrects",
        password: "Les mots de passe saisis sont incorrects",
        newPassword: "Les mots de passe saisis sont incorrects",
      }));
      setUpdate("");
    }

    if (!employee.oldPassword) {
      setError((prevError) => ({
        ...prevError,
        oldPassword:
          "Veuillez saisir votre ancien mot de passe s'il vous plait",
      }));
    }
    if (!employee.password) {
      setError((prevError) => ({
        ...prevError,
        password: "Veuillez saisir votre nouveau mot de passe s'il vous plait",
      }));
    }
    if (!employee.newPassword) {
      setError((prevError) => ({
        ...prevError,
        newPassword:
          "Veuillez répéter votre nouveau mot de passe s'il vous plait",
      }));
    }
  };

  const handleChange = (event) => {
    setEmployee({
      ...employee,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "oldPassword") {
      setError((prevError) => ({
        ...prevError,
        oldPassword:
          event.target.value.trim() !== ""
            ? ""
            : "Veuillez saisir votre ancien mot de passe s'il vous plait",
      }));
    } else if (event.target.name === "password") {
      setError((prevError) => ({
        ...prevError,
        password:
          event.target.value.trim() !== ""
            ? ""
            : "Veuillez saisir votre nouveau mot de passe s'il vous plait",
      }));
    } else if (event.target.name === "newPassword") {
      setError((prevError) => ({
        ...prevError,
        password:
          event.target.value.trim() !== ""
            ? ""
            : "Veuillez répéter votre nouveau mot de passe s'il vous plait",
      }));
    }
  };

  return (
    <div style={{ paddingTop: "2vh" }}>
      {update}
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "2vh",
          alignItems: "center",
        }}
      >
        <TextField
          label="Ancien mot de passe"
          name="oldPassword"
          type="password"
          onChange={(event) => handleChange(event)}
          value={employee.oldPassword}
          error={Boolean(error.oldPassword)}
          helperText={error.oldPassword}
          sx={inputField}
        />
        <TextField
          label="Nouveau mot de passe"
          name="password"
          type="password"
          onChange={(event) => handleChange(event)}
          value={employee.password}
          error={Boolean(error.password)}
          helperText={error.password}
          sx={inputField}
        />
        <TextField
          label="Répétition du nouveau mot de passe"
          name="newPassword"
          type="password"
          onChange={(event) => handleChange(event)}
          value={employee.newPassword}
          error={Boolean(error.newPassword)}
          helperText={error.newPassword}
          sx={inputField}
        />
        <span>
          Attention, vous serez déconnecté de votre session actuelle !
        </span>
        <Button variant="text" style={buttonUpdate} type="submit">
          Mettre à jour le mot de passe
        </Button>
      </form>
    </div>
  );
}

export default MyAccount;
