import {
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

export const SearchOptions = ({ handleChange, action, sortedActions }) => {
  const [searchText, setSearchText] = useState("");

  const normalizeAndSplitSearchText = (str) =>
    str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .split(/\s+/)
      .filter((word) => word !== "");

  const normalizeStringForSearch = (str) =>
    str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

  return (
    <Select
      name="action"
      value={action}
      onChange={handleChange}
      label="Choisir une action"
      MenuProps={{ autoFocus: false }}
      onClose={() => setSearchText("")}
    >
      <ListSubheader>
        <TextField
          size="small"
          // Autofocus on textfield
          autoFocus
          placeholder="Rechercher"
          fullWidth
          value={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
      {Object.keys(sortedActions).map((category) => {
        const filteredActions = sortedActions[category].filter((action) => {
          const searchWords = normalizeAndSplitSearchText(searchText);
          const actionName = normalizeStringForSearch(action.name);

          // Vérifier si chaque mot de recherche correspond à au moins un mot dans le nom de l'action
          return searchWords.every((word) => actionName.includes(word));
        });

        if (filteredActions.length > 0) {
          return [
            <ListSubheader key={category}>{category}</ListSubheader>,
            ...filteredActions.map((action) => (
              <MenuItem key={action.id} value={action.id}>
                {action.name}
              </MenuItem>
            )),
          ];
        }

        return null;
      })}
    </Select>
  );
};
