import { useEffect, useState, useCallback } from "react";
import useDocumentTitle, { fetchData } from "utils/functions/service";
import Cookies from "js-cookie";
import Employee from "./Employee";
import { buttonNew, focusStyle, itemList, selectedItem } from "utils/functions/themes";
import { useLocation } from "react-router-dom";
import Clouds from "utils/clouds/Clouds";
import ScrollBar from "utils/scrollbar/ScrollBar";
import Spinner from "utils/spinner/Spinner";

function EmployeesList() {
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState<
    Array<{ id: string; name: string; surname: string }>
  >([]);
  const [numEmployee, setNumEmployee] = useState(null);
  const [focus, setFocus] = useState(null);
  const roles = Cookies.get("roles");
  const location = useLocation();

  useDocumentTitle(`Liste des permanents`);

  const updateEmployees = async () => {
    setEmployees(await fetchData(`/employees`, roles));
  };

  const deleteEmployees = async (id: number) => {
    setNumEmployee(null);
    let newEmployees = await fetchData(`/employees`, roles);
    setEmployees(newEmployees.filter((a) => a.id !== id));
  };

  useEffect(() => {
    setNumEmployee(null);
    async function fetch() {
      setEmployees(await fetchData(`/employees`, roles));
    }
    fetch();
  }, [location, roles]);

  useEffect(() => {
    if (employees.length > 0) setIsLoading(false);
  }, [employees]);

  const handleKeyDown = useCallback((event, employeeId, fcnt) => {
    if (event.which === 13 || event.which === 32) {
      fcnt(employeeId);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "80vh",
        overflow: "hidden",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "48vw" }}>
        <ScrollBar>
          <div
            style={{
              rowGap: "3vh",
              paddingTop: "4vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              tabIndex={0}
              style={buttonNew}
              onClick={() => setNumEmployee(0)}
              onKeyDown={(event) => handleKeyDown(event, 0, setNumEmployee)}
            >
              Nouveau permanent
            </div>
            {isLoading ? (
              <Spinner showFirstText={false} />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  rowGap: "2vh",
                }}
              >
                {employees
                  .sort((a, b) => a.surname.localeCompare(b.surname))
                  .map((employee) => (
                    <div
                      tabIndex={0}
                      onFocus={() => setFocus(employee.id)}
                      onBlur={() => setFocus(null)}
                      key={employee.id}
                      onClick={() => setNumEmployee(employee.id)}
                      onKeyDown={(event) =>
                        handleKeyDown(event, employee.id, setNumEmployee)
                      }
                      style={
                        numEmployee && numEmployee === employee.id
                          ? Object.assign({}, itemList, selectedItem)
                          : focus === employee.id
                          ? Object.assign(
                              {},
                              itemList,
                              selectedItem,
                              focusStyle
                            )
                          : itemList
                      }
                    >
                      <div>
                        {employee.surname} {employee.name}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </ScrollBar>
      </div>
      <div
        style={{
          borderLeft: "0.2vw solid #203864",
          height: "80%",
          marginTop: "4vh",
        }}
      ></div>
      <div
        style={{
          width: "48vw",
          minHeight: "40vh",
        }}
      >
        {numEmployee !== null ? (
          <ScrollBar direction="left">
            <Employee
              id={numEmployee}
              updateEmployees={updateEmployees}
              deleteEmployees={deleteEmployees}
              mode={numEmployee !== 0 ? "update" : "create"}
              listEmployees={employees}
            />
          </ScrollBar>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1vw",
            }}
          >
            Veuillez sélectionner un permanent 😁
          </div>
        )}
      </div>
      <Clouds />
    </div>
  );
}

export default EmployeesList;
