import "moment/locale/fr";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDocumentTitle, { fetchData } from "utils/functions/service";
import Cookies from "js-cookie";
import { titleField, userFolder } from "utils/functions/themes";
import MonitoringsList from "./monitoring/MonitoringsList";
import ScrollBar from "utils/scrollbar/ScrollBar";
import UserDetail from "./UserDetail";

function UserPage({ monitoring = false }) {
  const navigate = useNavigate();
  const roles = Cookies.get("roles");

  const { id } = useParams();
  const [mode] = useState(id ? "update" : "create");
  const [user, setUser] = useState({
    name: "",
    surname: "",
    employee: { surname: "", name: "" },
    gender: "",
  });
  const [showMonitoring, setShowMonitoring] = useState(monitoring);
  const [totalMonitoring, setTotalMonitoring] = useState(0);

  useEffect(() => {
    async function fetch() {
      try {
        setUser(await fetchData(`/users/${id}`, roles));
        let monitorings = await fetchData(`/users/monitorings/${id}`, "admin");
        setTotalMonitoring(monitorings.length);
      } catch (error) {
        navigate("/users");
      }
    }
    if (mode === "update") {
      fetch();
    }
  }, [mode, id, navigate, roles]);

  useDocumentTitle(
    `${
      mode === "update"
        ? user.surname + " " + user.name + " - Modification"
        : "Nouveau bénéficiaire"
    }`
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginTop: "3vh",
        paddingTop: "0.6vh",
        position: "relative",
      }}
    >
      <ScrollBar>
        <div
          style={{
            display: "flex",
            width: "100vw",
            position: "absolute",
            justifyContent: "center",
            columnGap: "1rem",
            top: "0",
          }}
        >
          {id && (
            <>
              <span style={userFolder} onClick={() => setShowMonitoring(false)}>
                Fiche
              </span>
              <span style={userFolder} onClick={() => setShowMonitoring(true)}>
                Suivi
              </span>
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "5vh",
            padding: "0 2vw",
          }}
        >
          <span style={titleField}>
            {id ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span
                  style={{
                    zIndex: "0",
                  }}
                >
                  Profil de {user.surname} {user.name} :
                </span>
                {user.employee && (
                  <span>
                    Créé{user.gender === "Femme" ? "e" : ""} par{" "}
                    {user.employee.surname} {user.employee.name}
                  </span>
                )}
                <span>Nombre total de suivis : {totalMonitoring}</span>
              </div>
            ) : (
              "Création d'une fiche"
            )}
          </span>
        </div>
        <div>
          {!showMonitoring && <UserDetail id={id} />}
          {showMonitoring && <MonitoringsList id={id} />}
        </div>
      </ScrollBar>
    </div>
  );
}

export default UserPage;
