import "./scrollbar.css";

interface ScrollBarProps {
  children: React.ReactNode;
  direction?: "left" | "right";
}

function ScrollBar({ children, direction = "right" }: ScrollBarProps) {
  return (
    <div className="scroll-container">
      <div
        className={`scroll-content ${direction === "right" ? "ltr" : "rtl"}`}
      >
        {children}
      </div>
    </div>
  );
}

export default ScrollBar;
