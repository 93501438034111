import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, InputAdornment, IconButton } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { ReactComponent as KKooshii } from "images/k_kooshii.svg";
import { buttonLogin, inputLogin, labelLogin } from "utils/functions/themes";
import * as Icons from "@mui/icons-material";
import { getExpirationCookies } from "utils/functions/expirationCookies";
import Spinner from "utils/spinner/Spinner";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const isTouchScreen = "ontouchstart" in window;
  const isMobile = window.matchMedia("(max-width: 640px)").matches;
  const token = Cookies.get("token");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (token) {
      if (getExpirationCookies(token) > 0) {
        navigate("/users");
      }
    }
  }, [token, navigate]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const resetPassword = async () => {
    navigate("/resetPassword");
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/login`,
        {
          email: email,
          password: password,
        }
      );
      if (response.status === 201) {
        await Cookies.set("token", response?.data?.access_token, {
          secure: true,
          sameSite: "none",
          domain: "kooshii.fr",
        });
        await Cookies.set("roles", response?.data?.roles, {
          secure: true,
          sameSite: "none",
          domain: "kooshii.fr",
        });
        await Cookies.set("userid", response?.data?.userid, {
          secure: true,
          sameSite: "none",
          domain: "kooshii.fr",
        });
        await Cookies.set("user", response?.data?.user, {
          secure: true,
          sameSite: "none",
          domain: "kooshii.fr",
        });
        await Cookies.set("isTouchScreen", isTouchScreen, {
          secure: true,
          sameSite: "none",
          domain: "kooshii.fr",
        });
        await Cookies.set("isMobile", isMobile, {
          secure: true,
          sameSite: "none",
          domain: "kooshii.fr",
        });
        setPassword("");
        let backlink = localStorage.getItem("redirectPath");
        if (
          !backlink ||
          backlink === "/login" ||
          backlink === "/resetPassword" ||
          backlink === "/"
        )
          backlink = "/users";
        navigate(backlink);
        window.location.reload();
      }
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error.response?.data?.message ||
        "Identifiants invalides, veuillez réessayer.";
      setError(errorMessage);
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "radial-gradient(circle, #ffffff 40%, #ffe0b2 100%)",
      }}
    >
      <div
        style={{
          position: "fixed",
          backgroundColor: "#F1E511",
          height: "27vw",
          width: "27vw",
          bottom: "-4vw",
          right: "-4vw",
          borderRadius: "99999px",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#F1E511",
          height: "15vw",
          width: "45vw",
          bottom: "-4vw",
          right: "-4vw",
          borderTopLeftRadius: "999999px",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#F1E511",
          height: "45vw",
          width: "15vw",
          bottom: "-4vw",
          right: "-4vw",
          borderTopLeftRadius: "999999px",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#024873",
          height: "25vw",
          width: "25vw",
          top: "-4vw",
          left: "-4vw",
          borderRadius: "99999px",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#024873",
          height: "15vw",
          width: "40vw",
          top: "-4vw",
          left: "-4vw",
          borderBottomRightRadius: "999999px",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#024873",
          height: "40vw",
          width: "15vw",
          top: "-4vw",
          left: "-4vw",
          borderBottomRightRadius: "999999px",
        }}
      ></div>
      <div
        style={{
          marginTop: "7vh",
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          // logo
          style={{
            display: "flex",
            width: "100vw",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <KKooshii
            style={{ width: "10vw", minWidth: "10vw", maxWidth: "15vw" }}
          />
          <span style={{ fontSize: "2vw", marginBottom: "1vh" }}>
            onnectez-vous
          </span>
        </div>
        {!isLoading ? (
          <div
            style={{
              width: "50vw",
              marginTop: "10vh",
              display: "flex",
              flexDirection: "column",
              rowGap: "6vh",
            }}
          >
            <TextField
              InputLabelProps={{ style: labelLogin }}
              InputProps={{ style: inputLogin }}
              label="Identifiant"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onKeyDown={handleKeyDown}
              error={Boolean(error)} // ajout de l'erreur
              helperText={error}
            />
            <TextField
              label="Mot de passe"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onKeyDown={handleKeyDown}
              error={Boolean(error)} // ajout de l'erreur
              helperText={error}
              InputLabelProps={{ style: labelLogin }}
              InputProps={{
                style: { ...inputLogin },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Icons.Visibility />
                      ) : (
                        <Icons.VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <span
              style={{
                color: "black",
                fontSize: "1vw",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => resetPassword()}
            >
              Réinitialiser le mot de passe
            </span>
            <Button style={buttonLogin} onClick={handleSubmit}>
              Connexion
            </Button>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default LoginPage;
