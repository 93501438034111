import React from "react";
import * as XLSX from "xlsx";

interface Props {
  sheets: { name: string; headers: string[]; data: any[] }[];
  fileName: string;
  children?: React.ReactNode;
}

const Excel: React.FC<Props> = ({ sheets, fileName, children }) => {
  const workbook = XLSX.utils.book_new();

  sheets.forEach(({ name, headers, data }) => {
    const sheetData = [
      headers,
      ...data.map((row) => headers.map((header) => row[header])),
    ];
    const sheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, sheet, name);
  });

  const excelFile = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelFile], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);

  return (
    <a
      style={{
        fontWeight: 900,
        textAlign: "center",
        color: "#024873",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      href={url}
      download={`${fileName}-${new Date().getTime()}.xlsx`}
    >
      {children}
    </a>
  );
};

export default Excel;
