import { useEffect, useState, useCallback } from "react";
import useDocumentTitle, { fetchData } from "utils/functions/service";
import Cookies from "js-cookie";
import Funder from "./Funder";
import {
  buttonNew,
  focusStyle,
  itemList,
  selectedItem,
} from "utils/functions/themes";
import { useLocation } from "react-router-dom";
import Clouds from "utils/clouds/Clouds";
import ScrollBar from "utils/scrollbar/ScrollBar";
import Spinner from "utils/spinner/Spinner";

function FundersList() {
  const [isLoading, setIsLoading] = useState(true);
  const [funders, setFunders] = useState<
    Array<{ id: string; name: string; surname: string }>
  >([]);
  const [numFunder, setNumFunder] = useState(null);
  const [focus, setFocus] = useState(null);
  const roles = Cookies.get("roles");
  const location = useLocation();

  useDocumentTitle(`Liste des financeurs`);

  const updateFunders = async () => {
    setFunders(await fetchData(`/funders`, roles));
  };

  const deleteFunders = async (id: number) => {
    setNumFunder(null);
    let newFunders = await fetchData(`/funders`, roles);
    setFunders(newFunders.filter((a) => a.id !== id));
  };

  useEffect(() => {
    setNumFunder(null);
    async function fetch() {
      setFunders(await fetchData(`/funders`, roles));
    }
    fetch();
  }, [location, roles]);

  useEffect(() => {
    if (funders.length > 0) setIsLoading(false);
  }, [funders]);

  const handleKeyDown = useCallback((event, funderId, fcnt) => {
    if (event.which === 13 || event.which === 32) {
      fcnt(funderId);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "80vh",
        overflow: "hidden",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "48vw" }}>
        <ScrollBar>
          <div
            style={{
              rowGap: "3vh",
              paddingTop: "4vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              tabIndex={0}
              style={buttonNew}
              onClick={() => setNumFunder(0)}
              onKeyDown={(event) => handleKeyDown(event, 0, setNumFunder)}
            >
              Nouveau financeur
            </div>
            {isLoading ? (
              <Spinner showFirstText={false} />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  rowGap: "2vh",
                }}
              >
                {funders
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((funder) => (
                    <div
                      tabIndex={0}
                      onFocus={() => setFocus(funder.id)}
                      onBlur={() => setFocus(null)}
                      key={funder.id}
                      onClick={() => setNumFunder(funder.id)}
                      onKeyDown={(event) =>
                        handleKeyDown(event, funder.id, setNumFunder)
                      }
                      style={
                        numFunder && numFunder === funder.id
                          ? Object.assign({}, itemList, selectedItem)
                          : focus === funder.id
                          ? Object.assign(
                              {},
                              itemList,
                              selectedItem,
                              focusStyle
                            )
                          : itemList
                      }
                    >
                      <div>
                        {funder.surname} {funder.name}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </ScrollBar>
      </div>
      <div
        style={{
          borderLeft: "0.2vw solid #203864",
          height: "80%",
          marginTop: "4vh",
        }}
      ></div>
      <div
        style={{
          width: "48vw",
          minHeight: "40vh",
        }}
      >
        {numFunder !== null ? (
          <ScrollBar direction="left">
            <Funder
              id={numFunder}
              updateFunders={updateFunders}
              deleteFunders={deleteFunders}
              mode={numFunder !== 0 ? "update" : "create"}
              listFunders={funders}
            />
          </ScrollBar>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1vw",
            }}
          >
            Veuillez sélectionner un financeur 😁
          </div>
        )}
      </div>
      <Clouds />
    </div>
  );
}

export default FundersList;
