import { ReactComponent as Kooshii } from "images/kooshii.svg";

export default function NotFoundPage() {
  const fontSize = `calc(${(7 * window.innerHeight) / 1000}vh + ${
    (2 * window.innerWidth) / 1000
  }vw)`;
  const fontSizeText = `calc(${(3 * window.innerHeight) / 2000}vh + ${
    (1 * window.innerWidth) / 2000
  }vw)`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100vw",
          alignItems: "flex-end",
          justifyContent: "center",
          marginTop: "10vh",
        }}
      >
        <Kooshii
          style={{ width: "10vw", minWidth: "30vw", maxWidth: "15vw" }}
        />
      </div>
      <div
        style={{
          margin: "5vh 0",
          fontWeight: "900",
          fontSize: fontSize,
        }}
      >
        Oups...
      </div>
      <div
        style={{
          padding: "0 3vw",
          display: "flex",
          flexDirection: "column",
          rowGap: "2vh",
          textAlign: "center",
          fontSize: fontSizeText,
        }}
      >
        <p>Il semblerait que la page que vous ayez demandée n'existe pas.</p>
        <p>
          Si vous pensez que cela est une erreur, veuillez contacter un
          administrateur.
        </p>
      </div>
    </div>
  );
}
